<template>
  <div class="login-wrap">
    <div class="login-left"></div>
    <common-layout style="position: relative;">
      <div class="login-main">
        <div class="top">
          <div class="header">
            <span class="title">欢迎使用{{ systemName }}！</span>
          </div>
        </div>
        <div class="login">
          <a-form @submit="onSubmit" :form="form">
            <a-tabs size="large" :tabBarStyle="{ textAlign: 'center' }" style="padding: 0 2px;">
              <a-tab-pane>
                <a-alert type="error" :closable="true" v-show="error" :message="error" showIcon
                  style="margin-bottom: 24px;" />
                <a-form-item>
                  <a-input autocomplete="autocomplete" size="large" placeholder="请输入手机号"
                    v-decorator="['mobile', { rules: [{ required: true, message: '请输入手机号', whitespace: true }] }]">
                    <a-icon slot="prefix" type="user" />
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-row :gutter="8" style="margin: 0 -4px">
                    <a-col :span="16">
                      <a-input size="large" placeholder="请输入验证码"
                        v-decorator="['code', { rules: [{ required: true, message: '请输入验证码', whitespace: true }] }]">
                        <a-icon slot="prefix" type="mail" />
                      </a-input>
                    </a-col>
                    <a-col :span="8" style="padding-left: 4px">
                      <a-button style="width: 100%" class="captcha-button" size="large" @click="handleGetCode"
                        :disabled="countdown > 0">{{ buttonText }}</a-button>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-tab-pane>
            </a-tabs>
            <a-form-item>
              <a-button :loading="logging" style="width: 100%;margin-top: 24px" size="large" htmlType="submit"
                type="primary">登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </common-layout>
  </div>
</template>

<script>
import CommonLayout from '@/layouts/CommonLayout'
import { login, getRoutesConfig } from '@/services/user'
// import { login } from '@/services/user'
import { setAuthorization } from '@/utils/request'
import { loadRoutes } from '@/utils/routerUtil'
import { mapMutations } from 'vuex'
import routerMap from '@/router/async/router.map'
import { request, METHOD } from '@/utils/request'
import Cookies from 'js-cookie';
export default {
  name: 'Login',
  components: { CommonLayout },
  data() {
    return {
      logging: false,
      error: '',
      form: this.$form.createForm(this),
      countdown: 0, // 倒计时剩余时间
      buttonText: '获取验证码',
    }
  },
  computed: {
    systemName() {
      return this.$store.state.setting.systemName
    }
  },
  methods: {
    ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
    onSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true
          const mobile = this.form.getFieldValue('mobile')
          const code = this.form.getFieldValue('code')
          login(mobile, code).then(this.afterLogin)
        }
      })
    },
    getFingMsg(){
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/findReturnMessage', 'post',{}).then(res => {
        this.findMsg = res.data.data;
        this.findMsg.forEach(item=>{
          this.$notification['info']({
              message: '通知',
              description: item.messageContent,
              duration: null,
              key: item.id, // 指定通知的唯一 key
              onClose: () => {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/updateReturnMessage', 'post',{sourceId:item.sourceId})
              },
            });
        })
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/findExpectReturnMessage', 'post',{}).then(res => {
        this.findExpMsg = res.data.data;
        this.findExpMsg.forEach(item=>{
            this.$notification['warn']({
              message: '通知',
            description: item.messageContent,
            duration: null,
            key: item.id, // 指定通知的唯一 key
            onClose: () => {
              request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/updateExpectReturnMessage', 'post',{sourceId:item.sourceId})
            },
          });
        })
      })
    },
    handleGetCode() {
      const mobile = this.form.getFieldValue('mobile')
      if(!mobile) {
        this.$message.warning('请输入手机号')
        return;
      }
      if (this.countdown > 0) {
        return; // 如果倒计时还未结束，则不执行后续逻辑
      }
      this.countdown = 60;
      Cookies.remove('Authorization');
      request(process.env.VUE_APP_API_BASE_URL+'sfa/staff/login/sms/' + mobile, METHOD.POST).then(res => {
        if (res.data.code == 200) {
          setTimeout(() => {
            this.countdown = 60;
            this.startCountdown();
            this.$message.success('验证码已发送');
          }, 1000);
        } else {
          this.$message.error(res.data.message);
        }
      })
      // 执行获取验证码的逻辑，这里仅作演示
      // 在实际项目中，你需要发送请求到服务器获取验证码
      // 假设获取验证码成功后，设置倒计时为60秒
      // 这里使用 setTimeout 模拟异步操作

    },
    startCountdown() {
      if (this.countdown > 0) {
        this.countdown--;
        this.buttonText = `${this.countdown} 秒后重新获取`;
        setTimeout(this.startCountdown, 1000); // 每隔一秒更新倒计时文本
      } else {
        this.buttonText = '获取验证码';
      }
    },
    afterLogin(res) {
      this.logging = false
      const loginRes = res.data
      if (loginRes.code == 200) {
        res.data.permissions = [{ "id": "queryForm", "operation": loginRes.data.authorityList }]
        res.data.roles = [{ "id": "admin", "operation": loginRes.data.menuList }]
        this.$store.commit('account/setPermissions', { "id": "queryForm", "operation": loginRes.data.authorityList })
        this.setUser(loginRes.data)
        this.setPermissions(loginRes.permissions)
        this.setRoles(loginRes.roles)
        setAuthorization({ token: loginRes.data.token, expireAt: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) })
        let routesList = [], selectRoutes = []
        for (var key in routerMap) {
          routesList.push(key)
        }
        // 获取路由配置 
        getRoutesConfig().then(result => {
          console.log(result,'result')
          let routesChildren = result.data.data;
          routesChildren.forEach(item => {
            routesList.forEach(son => {
              if (item.code == son) {
                if (item.childrenList) {
                  selectRoutes.push({
                    router: item.code,
                    children: item.childrenList
                  })
                } else {
                  selectRoutes.push({
                    router: item.code
                  })
                }
              }
            })
          })
          selectRoutes.forEach(item => {
            if (item.children) {
              item.children.forEach((son, index) => {
                let obj = {
                  router: son.code,
                  path: son.code
                }
                if ( son.code == 'sfa_activity_details' ||son.code == 'sfa_demand_scheme' ||son.code == 'sfa_demand_details' || son.code == 'sfa_demand_add' || son.code == 'addForm' || son.code == 'Contact' || son.code == 'InvoiceList' || son.code == 'ContactAddress' || son.code == 'query_form' || son.code == 'addWorkOrder'|| son.code == 'detailsWorkOrder'|| son.code == 'editWorkOrder' || son.code == 'sfa_customer_details' || son.code == 'addInvoiceApply' || son.code == 'editInvoiceApply') {
                  obj.invisible = true;
                }

                item.children[index] = obj
              })
            }
          })
          const order = ['dashboard','sfa_sales_programs', 'sfa_commodity_management', 'sfa_customer', 'sfa_goOut','sfa_telephoneRecord','sfa_financial','sfa_resource', 'sfa_workOrder','sfa_mine'];
          selectRoutes.sort((a, b) => {
            const indexA = order.indexOf(a.router);
            const indexB = order.indexOf(b.router);
            return indexA - indexB;
          });
          selectRoutes.unshift({"router":"dashboard","children":["workplace"]})
          const routesConfig = [{
            router: 'root',
            children: selectRoutes
          }]
          console.log(routesConfig,'routesConfig')
          console.log(selectRoutes,'selectRoutes')
          loadRoutes(routesConfig)
          this.$router.replace('/dashboard/workplace')
          // if (selectRoutes[0].router == 'sfa_sales_programs') {
          //   this.$router.replace('sfa_sales_programs/product_solution').catch(err => (console.log(err, 'err')))
          // } else {
          //   this.$router.replace(selectRoutes[0].router + '/' + selectRoutes[0].children[0].router).catch(err => (console.log(err, 'err')))
          // }

          this.$message.success('登录成功', 3)
          this.getFingMsg()
        })
      } else {
        this.error = loginRes.message
      }
    }
  }
}
</script>

<style lang="less" scoped>
@primary-color: #5542F6;

.login-wrap {
  width: 100%;
  display: flex;
}

.login-left {
  width: 35%;
  background: url('https://qiniu.youjiamall.cn/sfa-admin-bg.jpg+ysjpg')no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.common-layout {
  flex: 1;

  .top {
    text-align: center;

    .header {
      height: 44px;
      line-height: 44px;
      margin-bottom: 50px;

      a {
        text-decoration: none;
      }

      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
      }

      .title {
        color: @title-color;
        position: relative;
        top: 2px;
        font-size: 38px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
    }

    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }

  .login {
    width: 620px;

    @media screen and (max-width: 576px) {
      width: 95%;
    }

    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }

    .icon {
      font-size: 24px;
      color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
  }
}


.login-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
}

::v-deep .ant-tabs-bar {
  border: none;
}

::v-deep .ant-tabs-nav-container {
  display: none;
}
</style>
