<template>
    <div class="card">
        <div style="display: flex;">
            <div class="title">销售单（{{ruleForm.statusDesc}}）</div>
            <p style="margin-left:auto;font-size: 15px;">销售单号：{{ ruleForm.code }}</p>
        </div>
        <a-descriptions title="">
            <a-descriptions-item label="买方信息">{{ ruleForm.customerName }}</a-descriptions-item>
            <a-descriptions-item label="卖方信息">{{ ruleForm.sellerName }}</a-descriptions-item>
            <a-descriptions-item label="销售单客户经理">{{ ruleForm.salespersonName }}</a-descriptions-item>
            <a-descriptions-item label="活动负责人">{{ ruleForm.assignStaffName }}</a-descriptions-item>
            <a-descriptions-item label="是否需要开票">{{ ruleForm.invoiceTypeDesc }}</a-descriptions-item>
            <a-descriptions-item label="开票金额">{{ ruleForm.invoiceAmount ? ruleForm.invoiceAmount.toFixed(2) : '0.00' }}</a-descriptions-item>
            <a-descriptions-item label="产品售价">{{ ruleForm.amount ? ruleForm.amount.toFixed(2) : '0.00' }}</a-descriptions-item>
            <a-descriptions-item label="M+">{{ ruleForm.businessExpenses ? ruleForm.businessExpenses.toFixed(2):'0.00' }}</a-descriptions-item>
            <a-descriptions-item label="合计优加底价">{{ ruleForm.plfPrice ? ruleForm.plfPrice.toFixed(2) : '0.00' }}</a-descriptions-item>
            <a-descriptions-item label="项目等级">{{ ruleForm.projectTypeDesc ? ruleForm.projectTypeDesc : '-' }}</a-descriptions-item>
            <a-descriptions-item label="创建时间">{{ formatDateMin(ruleForm.createTime) }}</a-descriptions-item>
            <a-descriptions-item label="销售时间">{{ formatDateMin(ruleForm.sellerTime) }}</a-descriptions-item>
            <a-descriptions-item label="最后更新时间">{{ formatDateMin(ruleForm.modifyTime) }}</a-descriptions-item>
        </a-descriptions>

        <p style="margin-top: 10px;color:#000;font-weight: 700;font-size: 16px;">产品类型：{{ ruleForm.typeDesc }}</p>
        <div class="title">物料信息</div>
        <a-table :columns="columns" :dataSource="ruleForm.materielList" :loading="tableLoading" :pagination="false" bordered
            :scroll="{ x: 1500 }">
            <template slot="createTime" slot-scope="text">{{ formatDateMin(text) }}</template>
            <template slot="orderTime" slot-scope="text">{{ formatDateMin(text) }}</template>

            <template slot="type" slot-scope="text">
                <span v-if="text == 20">货堆</span>
                <span v-if="text == 21">宅配</span>
            </template>
            <template slot="status" slot-scope="text">
                <span v-if="text == 1">正常</span>
                <span v-if="text == 2">取消</span>
            </template>
            <template slot="plfPrice" slot-scope="text">
                <span>{{text ? text.toFixed(2) : '0.00' }}</span>
            </template>
            <template slot="totalPlfPrice" slot-scope="text,record">
                <span>{{ getTotalPrice(record) }}</span>
            </template>
        </a-table>
        <div class="title" v-if="ruleForm.ordersKaList.length > 0">奖品信息</div>
        <a-table v-if="ruleForm.ordersKaList.length > 0" :columns="columns1" :dataSource="ruleForm.ordersKaList" :loading="tableLoading" :pagination="false" bordered
            :scroll="{ x: 1500 }">
            <template slot="createTime" slot-scope="text">{{ formatDateMin(text) }}</template>
            <template slot="orderTime" slot-scope="text">{{ formatDateMin(text) }}</template>

            <template slot="status" slot-scope="text">
                <span v-if="text == 1">正常</span>
                <span v-if="text == 2">取消</span>
            </template>

            <template slot="orderDetailPrice" slot-scope="text">
                {{ text ? text.toFixed(2) : '0.00'  }}
            </template>

            <template slot="orderDetailPlfPrice" slot-scope="text">
                {{ text ? text.toFixed(2) : '0.00'  }}
            </template>
            
            
            
        </a-table>
        <div class="title">人员信息</div>
        <a-table :columns="columns2" :dataSource="ruleForm.stafflList" :loading="tableLoading" :pagination="false" bordered
            :scroll="{ x: 1500 }">
            <template slot="createTime" slot-scope="text">{{ formatDateMin(text) }}</template>
            <template slot="orderTime" slot-scope="text">{{ formatDateMin(text) }}</template>
            <template slot="totalPlfPrice" slot-scope="text,record">
                <span>{{ getTotalPrice(record) }}</span>
            </template>
            <template slot="plfPrice" slot-scope="text">
                <span>{{text ? text.toFixed(2) : '0.00' }}</span>
            </template>
        </a-table>

        <!-- <p style="margin-top: 20px;font-size: 15px;font-weight: 700">合计优加底价：{{ ruleForm.plfPrice ? ruleForm.plfPrice.toFixed(2) : '0.00' }}元</p> -->
    </div>
</template>
<script>
import { request } from '@/utils/request'
export default {
    data() {
        return {
            dataSource: [],
            columns: [
                {
                    title: '物料名称',
                    dataIndex: 'name',
                    align: 'center'
                },
                // {
                //     title: '是否可重复使用',
                //     dataIndex: 'useRepeatDesc',
                //     align: 'center'
                // },
                {
                    title: '供应商ID',
                    dataIndex: 'supplierName',
                    align: 'center'
                },
                {
                    title: '数量',
                    dataIndex: 'quantity',
                    align: 'center'
                },
                {
                    title: '优加底价/件',
                    dataIndex: 'plfPrice',
                    scopedSlots: { customRender: 'plfPrice' },
                    align: 'center'
                },
                {
                    title: '优加底价/小计（数量*优加底价）',
                    dataIndex: 'totalPlfPrice',
                    scopedSlots: { customRender: 'totalPlfPrice' },
                    align: 'center'
                }
            ],
            columns1: [
                {
                    title: '订单类型',
                    dataIndex: 'typeDesc',
                    align: 'center'
                },
                {
                    title: '订单号',
                    dataIndex: 'code',
                    align: 'center'
                },
                // {
                //     title: '分销商名称',
                //     dataIndex: 'distributorName',
                //     align: 'center'
                // },
                {
                    title: '客户名称',
                    dataIndex: 'buyCustomerName',
                    align: 'center'
                },
                {
                    title: '销售单客户经理名称',
                    dataIndex: 'salespersonName',
                    align: 'center'
                },
                // {
                //     title: '销售单状态',
                //     dataIndex: 'status',
                //     scopedSlots: { customRender: 'status' },
                //     align: 'center'
                // },
                {
                    title: '商品总数量',
                    dataIndex: 'quantity',
                    align: 'center'
                },
                {
                    title: '优加底价/小计（数量*优加底价）',
                    dataIndex: 'orderDetailPlfPrice',
                    scopedSlots: { customRender: 'orderDetailPlfPrice' },
                    align: 'center'
                },
            ],
            columns2: [
                {
                    title: '人员类型',
                    dataIndex: 'typeDesc',
                    align: 'center'
                },
                {
                    title: '供应商ID',
                    dataIndex: 'supplierName',
                    align: 'center'
                },
                {
                    title: '人数',
                    dataIndex: 'quantity',
                    align: 'center'
                },
                {
                    title: '优加底价/人',
                    dataIndex: 'plfPrice',
                    scopedSlots: { customRender: 'plfPrice' },
                    align: 'center'
                },
              
                {
                    title: '优加底价/小计（数量*优加底价）',
                    dataIndex: 'totalPlfPrice',
                    scopedSlots: { customRender: 'totalPlfPrice' },
                    align: 'center'
                },
                // {
                //     title: '执行人员',
                //     dataIndex: 'taskStaffName',
                //     align: 'center'
                // },
                // {
                //     title: '执行费用说明',
                //     dataIndex: 'taskExplain',
                //     align: 'center'
                // },
            ],
            tableLoading: false,
            ruleForm: {}
        }
    },
    mounted() {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/offlineActivity/' + this.$route.query.id, 'post', {}).then(res => {
            this.ruleForm = res.data.data
        })
    },
    methods: {
        getTotalPrice(row) {
            return (row.plfPrice * row.quantity).toFixed(2)
        },
        formatDateMin(timestamp) {
            if (timestamp) {
                const date = new Date(timestamp);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
                return result;
            }
        },
    }
}
</script>
<style scoped>
.card {
    padding: 30px;
}

.card ::v-deep .ant-descriptions-title {
    font-size: 18px !important;
}

.card ::v-deep .ant-descriptions-item-label {
    font-size: 14px;
}

.card ::v-deep .ant-descriptions-item-content {
    font-size: 15px;
}

.title {
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 18px;
}
</style>