<template>
    <div style="min-height: calc(100vh - 88px);background: #F3F5F8;padding: 20px;">
        <p class="title">基本信息</p>
        <div class="card">
            <a-form-model :rules="rules" :form="form" ref="ruleForm" :model="form" @ok="handleOk" layout="inline">
                <a-row style="width:100%">
                    <a-form-model-item label="创建人" required style="width:150px;">
                        {{ form.createByDesc ? form.createByDesc : userInfo.name }}
                    </a-form-model-item>
                    <a-form-model-item label="客户名称" required>
                        <a-select show-search :value="searchVal" placeholder="请输入客户名称" style="width: 300px;" v-if="!$route.query.id"
                            :disabled="form.status > 10" :default-active-first-option="false" :show-arrow="false"
                            :filter-option="false" :not-found-content="null" @search="fetchUser"
                            @change="handleSearchChange">
                            <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                        <a-input v-else v-model="form.customerDesc" disabled style="width: 300px;"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="项目等级" required style="margin-left: 20px;">
                        <a-select :disabled="form.status > 10" v-model="form.projectType" style="width: 150px;"
                            size="middle" placeholder="请选择项目等级" :default-value="null" option-label-prop="label">
                            <a-select-option :value="item.id" :label="item.name"
                                v-for=" (item, index) in [{ id: '1', name: '投标' }, { id: '2', name: '非投标' }]"
                                :key="index">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="合计产品预算" required style="margin-left: 20px;">
                        ¥ {{ calcPrice() }}
                    </a-form-model-item>
                    <!-- <a-form-model-item label="预计成本售价" required style="margin-left: 20px;">
                        0.00
                    </a-form-model-item> -->
                </a-row>
                <a-row style="margin-top: 20px;">

                    <a-form-model-item label="需求名称" required>
                        <a-input placeholder="请输入需求名称" v-model="form.name" :max-length="60" :disabled="form.status > 10"
                            style="width: 35vw;"></a-input>
                    </a-form-model-item>
                </a-row>
            </a-form-model>
        </div>
        <div>
            <div class="title">
                慰问品
                <div v-if="form.status == 10 || !this.$route.query.id" style="margin:10px 0">
                    <a-button type="primary" style="margin-left:10px;" @click="addForm(0)">添加货堆</a-button>
                    <a-button type="primary" style="margin:0 10px;" @click="addForm(1)">添加宅配</a-button>
                    <a-button type="primary" @click="addForm(2)">添加定制提货券</a-button>
                </div>
            </div>
        </div>
        <div style="display:flex;width:100%;">
            <div style="width:100%;border:1px solid #eee" v-if="dataSource.length > 0">
                <a-table :columns="columns" :dataSource="dataSource" bordered :pagination="false">
                    <template slot="subType" slot-scope="record">
                        <span v-if="record == 101">货堆</span>
                        <span v-if="record == 102">宅配</span>
                        <span v-if="record == 103">提货券</span>
                    </template>
                    <template slot="action" slot-scope="record,text,index">
                        <!-- v-if="text.status == 10||text.status == 40" -->
                        <div v-if="!text.status || text.status != 120 && text.status == 10 || text.status == 40">
                            <a style="margin-right: 10px;" @click="edit(text, index)">编辑</a>
                            <a v-if="text.status != 40" @click="del(index, 1)">删除</a>
                        </div>
                    </template>
                    <template slot="marketPrice" slot-scope="record">
                        <span>{{ record.toFixed(2) }}</span>
                    </template>
                </a-table>
            </div>
            <div style="margin-left: 10px;width:100%;border:1px solid #eee" v-if="dataSource2.length > 0">
                <a-table :columns="columns2" :dataSource="dataSource2" bordered :pagination="false">
                    <template slot="subType" slot-scope="record">
                        <span v-if="record == 101">货堆</span>
                        <span v-if="record == 102">宅配</span>
                        <span v-if="record == 103">提货券</span>
                    </template>
                    <template slot="marketPrice" slot-scope="record">
                        <span>{{ record.toFixed(2) }}</span>
                    </template>

                    <template slot="action" slot-scope="record,text,index">
                        <!-- v-if="text.status == 10||text.status == 40" -->
                        <div v-if="!text.status || text.status != 120 && text.status == 10 || text.status == 40">
                            <a style="margin-right: 10px;" @click="edit(text, index)">编辑</a>
                            <a v-if="text.status != 40" @click="del(index, 2)">删除</a>
                        </div>
                    </template>
                </a-table>
            </div>


            <div v-if="dataSource.length == 0 && dataSource2.length == 0"
                style="width:100%;background: #fff;min-height: 300px;display: flex;align-items: center;justify-content: center">
                <a-empty />
            </div>
        </div>
        <div style="height:50px;"></div>
        <formModal ref="formZP" :visible="visible" :needStatus="needStatus" @sendForm="sendForm" @closeForm="closeForm"
            :detailsForm="detailsForm">
        </formModal>
        <formModalHD ref="formHD" :visibleHD="visibleHD" :needStatus="needStatus" @sendForm="sendForm"
            @closeFormHD="closeFormHD" :detailsForm="detailsForm">
        </formModalHD>
        <formModalTH :visibleTH="visibleTH" @sendForm="sendForm" :needStatus="needStatus" @closeFormTH="closeFormTH"
            :detailsForm="detailsForm">
        </formModalTH>
        <footer-tool-bar>
            <!--  -->
            <div v-if="this.isDetails">
                <a-button type="primary" v-if="form.status != 10" style="margin-right: 20px;" @click="printNeed">{{
                $t('打印需求单')
            }}</a-button>
                <!-- <a-button v-if="form.status == 20 && form.status != 120" style="margin-right: 20px;" @click="handleCancel(10)">{{
                $t('撤回')
            }}</a-button> -->
                <a-button type="danger" style="margin-right: 20px;" v-if="form.status != 120 && form.status != 10"
                    @click="handleCancel(120)">{{
                $t('取消需求单') }}</a-button>
                <a-button type="primary" v-if="form.status == 10"
                    style="margin-right: 20px;background: none;border: 1px solid #999999;color: #999999;"
                    @click="handleOk(10)">{{ $t('存草稿') }}</a-button>
                <a-button type="primary" @click="handleOk(20)" v-if="form.status == 10">{{
                $t('生成需求单')
            }}</a-button>
            </div>
            <div v-else>
                <a-button type="primary"
                    style="margin-right: 20px;background: none;border: 1px solid #999999;color: #999999;"
                    @click="handleOk(10)" :loading="loading">{{ $t('存草稿') }}</a-button>

                <a-button type="primary" @click="handleOk(20)" :loading="loading">{{
                $t('生成需求单')
            }}</a-button>
            </div>
        </footer-tool-bar>
        <a-modal title="取消原因" :visible="cancelOpen" :maskClosable="false" @ok="cancelNeed" :width="500"
            @cancel="cancelOpen = false; reason = null" :z-index="100">
            <a-textarea v-model="resonNeed"></a-textarea>
        </a-modal>
        <!-- 打印 -->
        <a-modal :visible="printVisit" :footer="null" width="80%" @cancel="printVisit = false">
            <div class="print-main" id="print">
                <h2 class="print-title">业务需求单</h2>
                <ul class="print-top">
                    <li>
                        客户经理：<span>{{ userInfo.name }}</span>
                    </li>
                    <li>
                        客户名称：<span>{{ form.customerDesc }}</span>
                    </li>
                    <li>
                        项目等级：<span>{{ form.projectTypeDesc }}</span>
                    </li>
                    <li>
                        预计产品售价：<span>{{ form.marketPrice }}</span>
                    </li>
                    <li>
                        需求单号：<span>{{ this.$route.query.code }}</span>
                    </li>
                    <li>
                        需求名称：<span>{{ form.name }}</span>
                    </li>
                </ul>
                <a-card title="需求方案信息" :bordered="false">
                    <div style="margin-top: 20px;">
                        <!-- <p>产品需求：{{ fa.info }}</p> -->
                        <div v-if="printData.length > 0">
                            <a-table :columns="printColumns1" :dataSource="printData" bordered :pagination="false">
                                <template slot="subType" slot-scope="record">
                                    <span v-if="record == 101">货堆</span>
                                    <span v-if="record == 102">宅配</span>
                                    <span v-if="record == 103">提货券</span>
                                </template>
                                <template slot="createTime" slot-scope="record">
                                    <span>{{ formatDateMin(record) }}</span>
                                </template>
                            </a-table>
                        </div>
                        <div v-if="printData2.length > 0" style="margin-top:10px;">
                            <a-table :columns="printColumns2" :dataSource="printData2" bordered :pagination="false">
                                <template slot="subType" slot-scope="record">
                                    <span v-if="record == 101">货堆</span>
                                    <span v-if="record == 102">宅配</span>
                                    <span v-if="record == 103">提货券</span>
                                </template>
                                <template slot="createTime" slot-scope="record">
                                    <span>{{ formatDateMin(record) }}</span>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </a-card>
                <div class="print-btm">
                    <p>客户经理：{{ userInfo.name }}</p>
                    <p>销售主管：</p>
                    <p>销售总监：</p>
                    <p>采购负责人：</p>
                    <p>销售负责人：</p>
                </div>
            </div>

            <div class="print-btn no-print">
                <a-button type="primary" style="margin-right: 10px;" v-print="printObj">打印预览</a-button>
                <a-button @click="printVisit = false">关闭</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { request } from '@/utils/request'
import FooterToolBar from '@/components/tool/FooterToolBar'
import formModal from './form_modal.vue'
import formModalHD from './form_modalHD.vue'
import formModalTH from './form_modalTH.vue'
import debounce from 'lodash/debounce';
import { EventBus } from '@/utils/event-bus.js';


export default {
    name: 'form',
    components: { FooterToolBar, formModal, formModalHD, formModalTH },
    data() {
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 900);
        return {
            loading: false,
            searchVal: [],
            form: {
                needOrderDetailList: []
            },
            dateValue: "",
            users: [],
            rules: {
                sellerId: [{ required: true, message: '请选择卖方主体', trigger: 'change' }],
                invoiceAmount: [{ required: true, message: '请输入合计开票金额', trigger: 'blur' }],
                businessExpenses: [{ required: true, message: '请输入M+', trigger: 'blur' }],
                sellingTime: [{ required: true, message: '请选择预期出库时间', trigger: 'change' }],
            },
            describeText: "",
            details: {},
            customerData: [],
            sellerList: [],//卖方主体
            expectReturnTime: "",
            dataSource: [],
            columns: [
                { title: '产品类型', dataIndex: 'subType', align: 'center', scopedSlots: { customRender: 'subType' } },
                { title: '产品预算/份', dataIndex: 'marketPrice', align: 'center', scopedSlots: { customRender: 'marketPrice' } },
                { title: '采购数量', dataIndex: 'buyCount', align: 'center' },
                // { title: '取消原因', dataIndex: 'cancelReason', align: 'center' },
                { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            dataSource2: [],
            columns2: [
                { title: '产品类型', dataIndex: 'subType', align: 'center', scopedSlots: { customRender: 'subType' } },
                { title: '产品预算', dataIndex: 'marketPrice', align: 'center', scopedSlots: { customRender: 'marketPrice' } },
                // { title: '取消原因', dataIndex: 'cancelReason', align: 'center' },
                { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            printColumns1: [
                { title: '产品类型', dataIndex: 'subType', align: 'center', scopedSlots: { customRender: 'subType' } },
                { title: '产品需求', dataIndex: 'info', align: 'center', ellipsis: true, },
                { title: '配送信息', dataIndex: 'addressInfo', align: 'center' },
                { title: '采购数量', dataIndex: 'buyCount', align: 'center' },
                { title: '产品预算/份', dataIndex: 'marketPrice', align: 'center' },
                { title: '期望完成时间', dataIndex: 'planExceptFinishTime', align: 'center' },
                { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
                { title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true, },

            ],
            printColumns2: [
                { title: '产品类型', dataIndex: 'subType', align: 'center', scopedSlots: { customRender: 'subType' } },
                { title: '产品需求', dataIndex: 'info', align: 'center', ellipsis: true, },
                { title: '配送信息', dataIndex: 'addressInfo', align: 'center' },
                { title: '产品预算', dataIndex: 'marketPrice', align: 'center' },
                { title: '期望完成时间', dataIndex: 'planExceptFinishTime', align: 'center' },
                { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
                { title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true, },
            ],
            visible: false,
            visibleHD: false,
            visibleTH: false,
            userInfo: {},
            selectType: -1,//当前新增的类型
            detailsForm: null,//编辑的数据
            editIndex: -1,
            isDetails: false,//编辑
            printVisit: false,
            printColumns: [],
            printData: [],
            printData2: [],
            needStatus: 0,
            printObj: {},
            resonNeed: "",
            cancelOpen: false
        }
    },
    watch: {
        $route() {
            // location.reload();
        },
    },
    mounted() {
        // 编辑
        if (this.$route.query.id) {
            // 获取详情数据
            this.initEditData();
            this.columns = [{ title: '产品类型', dataIndex: 'subType', align: 'center', scopedSlots: { customRender: 'subType' } },
            { title: '产品预算/份', dataIndex: 'marketPrice', align: 'center', scopedSlots: { customRender: 'marketPrice' } },
            { title: '采购数量', dataIndex: 'buyCount', align: 'center' },
            { title: '取消原因', dataIndex: 'cancelReason', align: 'center' },
            { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },]
            this.columns2 = [
                { title: '产品类型', dataIndex: 'subType', align: 'center', scopedSlots: { customRender: 'subType' } },
                { title: '产品预算/份', dataIndex: 'marketPrice', align: 'center', scopedSlots: { customRender: 'marketPrice' } },
                { title: '取消原因', dataIndex: 'cancelReason', align: 'center' },
                { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ]
            this.isDetails = true;

        } else {
            this.userInfo = this.$store.state.account.user
        }
        // 卖方主体
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
            this.sellerList = res.data.data;
        })
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        },
    },
    methods: {
        cancelNeed() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/updateStatus', 'post', {
                id: this.$route.query.id,
                status: 120,
                reason: this.resonNeed,
            }).then(res => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.$message.success('操作成功！');
                    this.cancelOpen = false;
                    EventBus.$emit('itemRemoved');
                    this.$router.replace('/sfa_demandList/sfa_demand_list')
                } else {
                    this.$message.error(res.data.message);
                    this.loading = false;
                }
            })
        },
        handleCancel() {
            this.resonNeed = ""
            this.cancelOpen = true;
        },
        //计算预计产品售价
        calcPrice() {
            let price = 0;
            if (this.dataSource2.length > 0) {
                this.dataSource2.forEach(item => {
                    price = price + item.marketPrice
                })
            }
            if (this.dataSource.length > 0) {
                this.dataSource.forEach(item => {
                    price = price + (item.marketPrice * item.buyCount)
                })
            }
            return price.toFixed(2)
        },
        initEditData() {
            // 需求单基本信息
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/' + this.$route.query.id, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    this.form = res.data.data;
                    this.needStatus = res.data.data.status
                    this.userInfo.name = this.form.staffDesc
                    this.handleSearchChange(res.data.data.customerId)
                    this.fetchUser(res.data.data.customerDesc)
                }
            })
            // 子需求列表
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/detailList/' + this.$route.query.id, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    this.dataSource = []
                    this.dataSource2 = []
                    res.data.data.forEach(item => {
                        this.getNeedDetails(item)
                        // if (item.subType == 101) {
                        //     this.dataSource2.push(item);
                        // } else {
                        //     this.dataSource.push(item);
                        // }
                    })
                }
            })
        },
        // 草稿时获取每条详情
        getNeedDetails(item) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/' + item.id, 'post', {}).then((res) => {
                if (item.subType == 101) {
                    this.dataSource2.push({ ...res.data.data, ...item });
                } else {
                    this.dataSource.push({ ...res.data.data, ...item });
                    // this.dataSource.push(res.data.data);
                }
            })

        },
        // 101 102 103 货堆 宅配 提货
        edit(record, index) {
            // if (record.status == 40) {
            //     request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/update', 'post', {}).then(res => {
            //         if (res.data.code == 200) {

            //         }
            //     })
            // } else {
            this.editIndex = index;
            if (record.subType == 101) {
                this.visibleHD = true;
                this.selectType = 0
            }
            if (record.subType == 102) {
                this.visible = true;
                this.selectType = 1

            }
            if (record.subType == 103) {
                this.visibleTH = true;
                this.selectType = 2
            }
            this.detailsForm = record;
            // }

        },
        del(index, type) {
            let that = this;
            this.$confirm({
                title: '您确定要删除吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    if (type == 1) {
                        that.dataSource.splice(index, 1)
                    }
                    if (type == 2) {
                        that.dataSource2.splice(index, 1)
                    }
                }
            });

        },
        // 保存子表单中的数据
        sendForm(data) {
            if (data) {
                if (this.selectType == 0) {
                    if (this.editIndex > -1) {
                        this.$set(this.dataSource2, this.editIndex, data);
                    } else {
                        this.dataSource2.push(data)
                    }
                    this.closeFormHD()
                }
                if (this.selectType == 1) {
                    if (this.editIndex > -1) {
                        this.$set(this.dataSource, this.editIndex, data);
                    } else {
                        this.dataSource.push(data)
                    }
                    this.closeForm()
                }
                if (this.selectType == 2) {
                    if (this.editIndex > -1) {
                        this.$set(this.dataSource, this.editIndex, data);
                    } else {
                        this.dataSource.push(data)
                    }
                    this.closeFormTH()
                }
            } else {
                this.closeFormHD()
                this.closeForm()
                this.closeFormTH()
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/detailList/' + this.$route.query.id, 'post', {}).then(res => {
                    if (res.data.code == 200) {
                        this.dataSource = []
                        this.dataSource2 = []
                        res.data.data.forEach(item => {
                            if (item.subType == 101) {
                                this.dataSource2.push(item);
                            } else {
                                this.dataSource.push(item);
                            }
                        })
                    }
                })
            }
        },
        closeForm() {
            this.visible = false;
            this.editIndex = -1
            this.detailsForm = null
        },
        closeFormHD() {
            this.visibleHD = false;
            this.detailsForm = null
            this.editIndex = -1
        },
        closeFormTH() {
            this.visibleTH = false;
            this.detailsForm = null
            this.editIndex = -1
        },
        addForm(type) {
            this.selectType = type;
            if (type == 0) {
                this.visibleHD = true;
                this.$refs.formHD.initAddress()
            }
            if (type == 1) {
                this.visible = true
                this.$refs.formZP.initAddress()
            }
            if (type == 2) {
                this.visibleTH = true
            }
        },
        fetchUser(value) {
            if (!value) {
                return false;
            }
            this.lastFetchId += 1;
            const fetchId = this.lastFetchId;
            this.customerData = [];
            this.fetching = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/self/listByCustomerName', 'post', { page: 0, size: 50, name: value }).then(res => {
                if (fetchId !== this.lastFetchId) {
                    return;
                }
                this.customerData = res.data.data.data;
                this.fetching = false;
            }).catch(error => {
                console.error('Error fetching user:', error);
            });
        },
        handleSearchChange(searchVal) {
            this.form.customerId = searchVal;
            console.log(searchVal, 'val')
            Object.assign(this, {
                searchVal,
                fetching: false,
            });
            const selectedCustomer = this.customerData.find(customer => customer.id === searchVal);
            if (!this.form.name) {
                this.form.name = this.getNowDate() + selectedCustomer.name
            }
        },
        getNowDate() {
            // 获取当前日期和时间
            const now = new Date();
            // 获取年月日时分秒
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1并格式化为2位
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}${month}${day}${hours}${minutes}${seconds}`
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
            return result;
        },
        handleBlur() {
            this.$refs.myCard.calcDiscount();
        },
        getAmount() {
            // businessExpenses业务支出     invoiceAmount 开票金额
            this.form.amount = this.form.invoiceAmount - this.form.businessExpenses
            return this.form.invoiceAmount - this.form.businessExpenses
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        // 选择日期
        dateChange(date, dateString) {
            this.form.sellingTime = dateString;
            this.dateValue = dateString
        },
        hkChange(date, dateString) {
            this.form.expectReturnTime = dateString;
            this.expectReturnTime = dateString
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        back() {
            this.$router.replace('/sfa_workOrder/OutboundList')
        },
        // handleCancel(status) {
        //     request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/updateStatus', 'post', {
        //         id: this.$route.query.id,
        //         status: status,
        //     }).then(res => {
        //         this.loading = false;
        //         if (res.data.code == 200) {
        //             this.$message.success('操作成功！');
        //             this.initEditData();
        //             // EventBus.$emit('itemRemoved');
        //             // this.$router.replace('/sfa_demandList/sfa_demand_list')
        //         } else {
        //             this.$message.error(res.data.message);
        //             this.loading = false;
        //         }
        //     })
        // },
        handleOk(status) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (!this.form.customerId) {
                        this.$message.error('请选择客户！')
                        return false;
                    }
                    if (!this.form.name) {
                        this.$message.error('请输入需求名称！')
                        return false;
                    }
                    if (!this.form.projectType) {
                        this.$message.error('请选择项目等级！')
                        return false;
                    }
                    this.form.needOrderDetailList = []
                    // if(!this.form.needOrderDetailList) {
                    //         this.form.needOrderDetailList = []
                    // }
                    if (this.dataSource.length > 0) {

                        this.dataSource.forEach(item => {
                            this.form.needOrderDetailList.push(item)
                        })
                    }
                    if (this.dataSource2.length > 0) {
                        this.dataSource2.forEach(item => {
                            this.form.needOrderDetailList.push(item)
                        })
                    }
                    if (this.form.needOrderDetailList.length == 0) {
                        this.$message.error('请添加需求类型！')
                        return false;
                    }
                    this.loading = true;
                    this.form.status = status;

                    if (!this.$route.query.id) {
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/create', 'post', this.form).then(res => {
                            this.loading = false;
                            if (res.data.code == 200) {
                                this.$message.success('操作成功！');
                                EventBus.$emit('itemRemoved');
                                this.$router.replace('/sfa_demandList/sfa_demand_list')
                            } else {
                                this.$message.error(res.data.message);
                                this.loading = false;
                            }
                        })
                    } else {
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/update', 'post', this.form).then(res => {
                            this.loading = false;
                            if (res.data.code == 200) {
                                this.$message.success('操作成功！');
                                EventBus.$emit('itemRemoved');
                                this.$router.replace('/sfa_demandList/sfa_demand_list')
                            } else {
                                this.$message.error(res.data.message);
                                this.loading = false;
                            }
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        printNeed() {
            this.printData2 = []
            this.printData = []
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/print/' + this.form.id, 'post', {}).then(res => {
                res.data.data.forEach(item => {
                    if (item.subType == 101) {
                        this.printData2.push(item);
                    } else {
                        this.printData.push(item);
                    }
                })
                this.printVisit = true;
            })
        }

    }
}
</script>

<style lang="less" scoped>
.card {
    margin-bottom: 24px;
}

::v-deep .ant-card-body {
    padding: 24px !important;
}

.price-box {
    display: flex;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(85, 66, 246, 0.03);
}

.price-box div {
    padding: 10px 0;
    box-sizing: border-box;
    flex: 1;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #010101;
    text-align: center;
}

.price-box span {
    font-size: 18px;
    color: rgba(255, 0, 0, 1);
}

::v-deep .ant-calendar-footer-extra .ant-tag {
    color: #5542F6 !important;
}

::v-deep .ant-calendar-footer-extra .ant-tag-blue {
    color: #5542F6 !important;
}

.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

#components-form-demo-advanced-search .ant-form {
    max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}


.title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #010101;
}

.card {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
}

::v-deep .ant-table-tbody {
    background: #fff;
}

:v-deep .ant-table-wrapper {
    width: 80%;
}

.print-main ::v-deep .ant-card-body {
    padding: 12px !important;
}

.print-main ::v-deep .ant-card-head {
    border: 0 !important;
    min-height: 0 !important;
    padding: 0 12px !important;
}

.print-main ::v-deep .ant-card-head-title {
    padding: 0 !important;
}

.print-btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

.print-title {
    text-align: center;
    font-size: 24px;

}

.print-table {
    margin-bottom: 30px;
}

.print-top {
    display: flex;
    justify-content: center;
    color: #000;
    font-weight: 500;
    flex-wrap: wrap;
    padding: 0 12px;
}

.print-top li {
    width: 33.33%;
    margin-bottom: 16px;
    font-size: 16px;
}

.print-top span {
    font-weight: 500;
    color: #000;
}

.print-main {
    width: 100%;
    margin: auto;
    height: 100%;
}

.print-btm {
    display: flex;
    padding: 10px;
    font-size: 16px;
    margin-top: 40px;
    height: 100px;
    font-weight: 500;
    color: #000;
}

.print-btm p {
    flex: 1;
    color: #000;
}

.print-table ::v-deep .ant-card {
    font-size: 16px;
    color: #000 !important;
    font-weight: 500;
}

.print-table ::v-deep .ant-table {
    font-size: 16px;
    color: #000 !important;
    font-weight: 500;
}

.print-main ::v-deep .ant-card-head {
    border: 0 !important;
    min-height: 0 !important;
    padding: 0 12px !important;
}

/* 自定义表格线颜色 */
.print-main ::v-deep .ant-table-tbody>tr>td {
    border-color: #333;
    /* 设置表格单元格的边框颜色 */
}

/* 可选：设置表格头部线颜色 */
.print-main ::v-deep .ant-table-thead>tr>th {
    border-color: #333;
    /* 设置表格头部单元格的边框颜色 */
}

/* 可选：设置表格外边框颜色 */
.print-main ::v-deep .ant-table {
    border-color: #000;
    /* 设置表格的外边框颜色 */
}

.print-main ::v-deep .ant-table-bordered .ant-table-body>table {
    border-color: #333;
    /* 设置表格的外边框颜色 */
}

.print-main table {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-spacing: 0;

}

.print-main table {
    width: 100%;
}

.print-main tr {
    width: 150px;
    height: 60px;
    border-bottom: 1px solid #000;
    padding: 8px 10px;
    border-right: 1px solid #000;
    line-height: 20px;
    text-align: center;
}

.print-main td {
    width: 150px;
    height: 60px;
    border-bottom: 1px solid #000;
    padding: 8px 10px;
    border-right: 1px solid #000;
    line-height: 20px;
    text-align: center;
}
</style>
<!-- 打印样式表 -->
<style media="print">
/* 设置打印时的样式 */
@media print {

    /* 隐藏不需要打印的元素 */
    .no-print {
        display: none;
    }

    .print-main {
        zoom: 65%;
        page-break-after: always;
        padding: 30px 0;
    }

    .ant-table {
        /* page-break-inside: avoid; */
    }

    /* 隐藏日期和网址 */
    @page {
        margin-top: 8mm;
    }

}
</style>