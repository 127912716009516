<template>
  <div>
    <a-card style="background-color: #fff;">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="6" :sm="24" style="max-width: 400px;margin-left:10px">
                <a-form-item label="销售单号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-input v-model="queryData.code" placeholder="请输入销售单号" />
                </a-form-item>
              </a-col>

              <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="分销商" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.distributorCode" style="width: 100%" placeholder="请选择分销商"
                    option-label-prop="label">
                    <a-select-option value="20220601001" label="优加惠品">
                      优加惠品
                    </a-select-option>
                    <a-select-option value="20221215001" label="好乐滋">
                      好乐滋
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <!-- 
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.type" style="width: 100%" placeholder="请选择类型"
                    option-label-prop="label">
                    <a-select-option value="1" label="次票">次票</a-select-option>
                    <a-select-option value="3" label="储值卡">储值卡</a-select-option>
                    <a-select-option value="4" label="次票">礼品兑换卡</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->

              <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="介质" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.mediaType" style="width: 100%" placeholder="请选择介质"
                    option-label-prop="label">
                    <a-select-option value="1" label="电子卡">电子卡</a-select-option>
                    <a-select-option value="2" label="实体卡">实体卡</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->

              <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="买方类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.buyCustomerType" style="width: 100%" placeholder="请选择买方类型"
                    option-label-prop="label">
                    <a-select-option value="1" label="公司">公司</a-select-option>
                    <a-select-option value="2" label="个人">个人</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <!-- </a-row> -->
              <!-- <a-row> -->
              <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="出库批次号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-input v-model="queryData.code" placeholder="请输入出库批次号" />
                </a-form-item>
              </a-col> -->
              <a-col :md="6" :sm="24" style="max-width: 400px;margin-left:10px">
                <a-form-item label="客户名称" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select show-search :value="searchVal" placeholder="请输入客户名称" :default-active-first-option="false"
                    :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
                    @change="handleSearchChange">
                    <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="销售单客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.salespersonId"  style="width: 100%"  placeholder="请选择销售单客户经理"
                    :default-value="null" :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in usersAll" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <a-col :md="6" :sm="24" style="max-width: 400px;margin-left:10px">
                <a-form-item label="当前客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.staffIdList" mode="multiple" style="width: 100%" placeholder="请选择客户经理"
                    :default-value="null" :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="出库客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.salespersonIdList" style="width: 100%" mode="multiple"
                    placeholder="请选择出库客户经理" :default-value="null" :filter-option="filterOption"
                    option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->

              <a-col :md="6" :sm="24" style="max-width: 400px;margin-left:10px">
                <a-form-item label="卖方信息" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select v-model="queryData.sellerId" style="width: 100%" placeholder="请选择卖方信息" :default-value="null"
                    :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in sellerList"
                      :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;margin-left:10px">
                <a-form-item label="提交时间" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-range-picker :value="dateValue" :ranges="{
        '今天': [moment(), moment()],
        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '当月': [moment().startOf('month'), moment().endOf('month')],
        '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }" @change="dateChange" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;margin-left:10px;">
                <a-form-item label="预计回款时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 15, offset: 1 }">
                  <a-range-picker :value="dateReturnPrice" :ranges="{
        '今天': [moment(), moment()],
        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '当月': [moment().startOf('month'), moment().endOf('month')],
        '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }" @change="dateReturnChange" />
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="margin-bottom: 10px;">
                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;" @click="search">查询
                </a-button>

              </a-col>
            </a-row>

            <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至
              {{ queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项
            </div>
          </div>

        </a-form>

      </div>


      <div>
        <ul class="spin-content">
          <li>合计产品售价:{{ getLocale(totalText.amount) }}</li>
          <li>合计开票金额:{{ getLocale(totalText.invoiceAmount) }}</li>
          <li>合计已开票金额:{{ getLocale(totalText.invoiceAmountAlready) }}</li>
          <li>合计税费:{{ getLocale(totalText.invoiceTaxAmount) }}</li>
          <li>合计业绩:{{ getLocale(totalText.perfAmount) }}</li>
          <li>合计M+:{{ getLocale(totalText.businessExpenses) }}</li>
          <li>合计M+税后:{{ getLocale(totalText.businessExpenses * 0.94) }}</li>
          <li>合计印刷成本:{{ getLocale(totalText.printAmount) }}</li>
          <li>合计大单奖励:{{ getLocale(totalText.rewardAmount) }}</li>
          <li>合计提成:{{ getLocale(totalText.comAmount) }}</li>
          <li>合计回款金额:{{ getLocale(totalText.repayAmount) }}</li>
        </ul>
        <a-table :columns="columns" :dataSource="dataSource" bordered :loading="tableLoading" :pagination="false"
          size="small" :scroll="{ x: 1500 }">
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="sellingTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="expectReturnTime" slot-scope="text">{{ formatDateNoMin(text) }}</template>

          <template slot="type" slot-scope="text">
            <span v-if="text == 1">次票</span>
            <span v-if="text == 3">储值卡</span>
            <span v-if="text == 4">礼品兑换卡</span>
          </template>
          <template slot="mediaType" slot-scope="text">
            <span v-if="text == 1">电子卡</span>
            <span v-if="text == 2">实体卡</span>
          </template>
          <template slot="buyCustomerType" slot-scope="text">
            <span v-if="text == 1">公司</span>
            <span v-if="text == 2">个人</span>
          </template>
          <!-- <template slot="amount" slot-scope="text,record">
            {{ record }}
          </template> -->

          <!--  是否全额返款 -->
          <template slot="sfaSaleRepayList" slot-scope="text,record">
            <div>
              <div v-if="record.sfaSaleRepayList.length > 0">
                {{ getContent(record) }}
              </div>
              <div v-else>否</div>
            </div>
          </template>

          <!-- 回款 -->
          <template slot="outCardCom" slot-scope="text,record">
            <div>
              <div v-if="record.sfaSaleRepayList && record.sfaSaleRepayList.length > 0">
                <div v-for="(item, idx) in record.sfaSaleRepayList" :key="idx">{{ item.amount }}</div>
              </div>
              <span v-else>-</span>
            </div>
          </template>

          <!-- 合同 -->
          <template slot="sfaSaleContract" slot-scope="text,record">
            <div>
              <div v-if="record.sfaSaleContract">
                <div v-if="record.sfaSaleContract.type == 2">
                  需要签订
                  <p style="margin:0;padding:0" v-if="record.sfaSaleContract.quantity">签订数量：{{
        record.sfaSaleContract.quantity }}</p>
                  <p style="margin:0;padding:0" v-if="record.sfaSaleContract.revertQuantity">需回数量：{{
        record.sfaSaleContract.revertQuantity }}</p>
                  <p style="margin:0;padding:0" v-if="record.sfaSaleContract.alreadyQuantity">已回数量：{{
        record.sfaSaleContract.alreadyQuantity }}</p>
                </div>
                <div v-else>
                  不需要签订
                </div>
              </div>
              <div v-if="!record.sfaSaleContract">-</div>
            </div>
          </template>
          <!-- 印刷成本 -->

          <template slot="sfaSalePrint" slot-scope="text,record">
            <div>
              <span v-if="record.sfaSalePrint">{{ record.sfaSalePrint.amount }}</span>
              <span v-else>-</span>
            </div>

          </template>

          <template slot="sfaSaleComList" slot-scope="text,record">
            <span>
              {{ getContentcom(record) }}
            </span>
          </template>

          <template slot="outCardRepayList" slot-scope="text">
            <div>
              <div v-if="text">
                <div v-for="item in text" :key="item">
                  <p>{{ item.amount }}</p>
                </div>
              </div>
              <div v-else>-</div>
            </div>
          </template>

          <!-- 有效业绩 -->
          <template slot="sfaSalePerf" slot-scope="text,record">
            <div>
              <div v-if="record.sfaSalePerf">
                <span>{{ record.sfaSalePerf.amount ? record.sfaSalePerf.amount : '-' }}</span>
              </div>
              <div v-else>-</div>
            </div>
          </template>
          <!-- 大单奖励 -->
          <template slot="sfaSaleReward" slot-scope="text,record">
            <div>
              <div v-if="record.sfaSaleReward">
                <span>{{ record.sfaSaleReward.amount ? record.sfaSaleReward.amount : '-' }}</span>
              </div>
              <div v-else>-</div>
            </div>
          </template>

          <template slot="outCodeTime" slot-scope="text">{{text ? formatDate(text) : '-' }}</template>

        </a-table>
        <div class="page-box">
          <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
            :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
            show-size-changer />
        </div>


      </div>

    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { publicMixin } from '@/mixin/public'
import debounce from 'lodash/debounce';

// const renderContent = (value) => {
//   const obj = {
//     children: value,
//     attrs: {},
//   };
//   return obj;
// };

export default {
  name: 'QueryList',
  mixin: [publicMixin],
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      searchVal:[],
      totalText: {},
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: [
        // { title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80, },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          customRender: (value, row, index) => {
            if (value) {
              value = this.formatDate(value);
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          // scopedSlots: { customRender: 'createTime' },
          width: 220,
          align: 'center'
        },
        
        {
          title: '销售单号',
          dataIndex: 'code',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 200,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'statusName',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: '销售单客户经理',
          dataIndex: 'salespersonName',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: '当前客户经理',
          dataIndex: 'customerStaffName',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        // {
        //   title: '优加底价',
        //   dataIndex: 'salPrice',
        //   customRender: (value, row, index) => {
        //     const obj = {
        //       children: value,
        //       attrs: {},
        //     };
        //     let result = this.findDuplicateIds(this.dataSource);
        //     result.forEach(item => {
        //       if (item.id == row.id) {
        //         obj.attrs.rowSpan = 0;
        //         if (index == item.indexes[0]) {
        //           obj.attrs.rowSpan = item.indexes.length;
        //         }
        //       }
        //     })
        //     return obj;
        //   },
        //   // scopedSlots: { customRender: 'amount' },
        //   width: 120,
        //   align: 'center'
        // },
        {
          title: '产品售价',
          dataIndex: 'amount',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          // scopedSlots: { customRender: 'amount' },
          width: 120,
          align: 'center'
        },
        
        // {
        //   title: '是否需要开票',
        //   dataIndex: 'invoiceName',
        //   width: 100,
        //   align: 'center'
        // },
        {
          title: '开票金额',
          dataIndex: 'invoiceAmount',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        // {
        //   title: '已开票金额',
        //   dataIndex: 'invoiceAmountAlready',
        //   customRender: (value, row, index) => {
        //     const obj = {
        //       children: value,
        //       attrs: {},
        //     };
        //     let result = this.findDuplicateIds(this.dataSource);
        //     result.forEach(item => {
        //       if (item.id == row.id) {
        //         obj.attrs.rowSpan = 0;
        //         if (index == item.indexes[0]) {
        //           obj.attrs.rowSpan = item.indexes.length;
        //         }
        //       }
        //     })
        //     return obj;
        //   },
        //   width: 120,
        //   align: 'center'
        // },
        // {
        //   title: '税费',
        //   dataIndex: 'invoiceTaxAmount',
        //   customRender: (value, row, index) => {
        //     const obj = {
        //       children: value,
        //       attrs: {},
        //     };
        //     let result = this.findDuplicateIds(this.dataSource);
        //     result.forEach(item => {
        //       if (item.id == row.id) {
        //         obj.attrs.rowSpan = 0;
        //         if (index == item.indexes[0]) {
        //           obj.attrs.rowSpan = item.indexes.length;
        //         }
        //       }
        //     })
        //     return obj;
        //   },
        //   width: 120,
        //   align: 'center'
        // },
        {
          title: 'M+',
          dataIndex: 'businessExpenses',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: 'M+税后',
          dataIndex: 'businessExpensesTax',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          // scopedSlots: { customRender: 'businessExpensesTax' },
          width: 120,
          align: 'center'
        },
        {
          title: '印刷成本',
          dataIndex: 'sfaSalePrint',
          customRender: (value, row, index) => {
            console.log(row,'row')
            if (value) {
              value = row.sfaSalePrint.amount

            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          // scopedSlots: { customRender: 'sfaSalePrint' },
          width: 100,
          align: 'center'
        },
        {
          title: '预计回款时间',
          dataIndex: 'expectReturnTime',
          customRender: (value, row, index) => {
            console.log(row,'row')
            if (value) {
              value = this.formatDateNoMin(value)
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          // scopedSlots: { customRender: 'expectReturnTime' },
          width: 120,
          align: 'center'
        },
        {
          title: '是否全额回款',
          dataIndex: 'sfaSaleRepayList',
          customRender: (value, row, index) => {
            console.log(row,'row')
            if (value) {
              value = this.getContent(row)
            } else {
              value = '否'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          
          // scopedSlots: { customRender: 'sfaSaleRepayList' },
          width: 150,
          align: 'center'
        },
        {
          title: '回款金额',
          dataIndex: 'outCardCom',
          // scopedSlots: { customRender: 'outCardCom' },
          customRender: (value, row, index) => {
            value = this.getSfaSaleRepayList(row)
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: '业绩',
          dataIndex: 'sfaSalePerf',
          scopedSlots: { customRender: 'sfaSalePerf' },
          customRender: (value, row, index) => {
            if (value) {
              value = row.sfaSalePerf.amount ?  row.sfaSalePerf.amount : '-'
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: '大单奖励',
          dataIndex: 'sfaSaleReward',
          // scopedSlots: { customRender: 'sfaSaleReward' },
          customRender: (value, row, index) => {
            console.log(row,'row.sfaSaleReward.amount')
            if (value) {
              value = row.sfaSaleReward.amount ?  row.sfaSaleReward.amount : '-'
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 120,
          align: 'center'
        },
        {
          title: '是否签订合同',
          dataIndex: 'sfaSaleContract',
          customRender: (value, row, index) => {
            if (value) {
              value = this.getSfaCont(row)
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 200,
          align: 'center'
        },
        {
          title: '提成',
          dataIndex: 'sfaSaleComList',
          customRender: (value, row, index) => {
            if (value) {
              value = this.getContentcom(row)
            } else {
              value = '-'
            }
            console.log(value,'value')
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 150,
          align: 'center'
        },
        {
          title: '预期出库时间',
          dataIndex: 'sellingTime',
          customRender: (value, row, index) => {
            if (value) {
              value = this.formatDateNoMin(value);
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          width: 220,
          align: 'center'
        },
        {
          title: '分销商名称',
          dataIndex: 'distributorName',
          // customRender: (value, row, index) => {
          //   const obj = {
          //     children: value,
          //     attrs: {},
          //   };
          //   let result = this.findDuplicateIds(this.dataSource);
          //   result.forEach(item => {
          //     if (item.id == row.id) {
          //       obj.attrs.rowSpan = 0;
          //       if (index == item.indexes[0]) {
          //         obj.attrs.rowSpan = item.indexes.length;
          //       }
          //     }
          //   })
          //   return obj;
          // },
          align: 'center',
          width: 220
        },
        {
          title: '产品类型',
          dataIndex: 'typeName',
          // customRender: (value, row, index) => {
          //   const obj = {
          //     children: value,
          //     attrs: {},
          //   };
          //   let result = this.findDuplicateIds(this.dataSource);
          //   result.forEach(item => {
          //     if (item.id == row.id) {
          //       obj.attrs.rowSpan = 0;
          //       if (index == item.indexes[0]) {
          //         obj.attrs.rowSpan = item.indexes.length;
          //       }
          //     }
          //   })
          //   return obj;
          // },
          width: 150,
          align: 'center'
        },
        {
          title: '产品名称',
          dataIndex: 'giftName',
          width: 150,
          align: 'center'
        },
        {
          title: '出库状态',
          dataIndex: 'outStatusName',
          width: 150,
          align: 'center'
        },
        {
          title: '激活状态',
          dataIndex: 'actStatusName',
          width: 150,
          align: 'center'
        },
        {
          title: '卖方',
          dataIndex: 'sellerName',
          width: 150,
          align: 'center'
        },
        {
          title: '出库时间',
          dataIndex: 'outCodeTime',
          scopedSlots: { customRender: 'outCodeTime' },
          width: 150,
          align: 'center'
        },
      ],
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],

      // 筛选
      queryData: {
        page: 0,
        size: 10,
        type: 1,
      },

      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0,
      currentPage: 1,
      total: 0,
      brandList: [],
      pdtCatList: [],
      dateValue: "",
      users: [],
      // 卖方信息
      sellerList: [],
      dateReturnPrice: "",
      usersAll:[]
    }
  },
  mounted() {
    // 列表
    this.getData();
    // 销售
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post',{}).then(res => {
      this.users = res.data.data;
    })
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/staff/list/1', 'get').then(res => {
      this.usersAll = res.data.data;
    })
    // // 客户列表
    // request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
    //   this.customerData = res.data.data
    // })
    // 卖方信息
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
      this.sellerList = res.data.data
    })


  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    getSfaCont(record){
        if(record.sfaSaleContract && record.sfaSaleContract.type ==2 ) {
          let str = ""
          if(record.sfaSaleContract.quantity){
            str += "签订数量:" + record.sfaSaleContract.quantity + "|";
          } 
          if(record.sfaSaleContract.revertQuantity) {
            if(record.sfaSaleContract.alreadyQuantity) {
              str += "需回数量:" + record.sfaSaleContract.revertQuantity + "|";
            } else {
              str += "需回数量:" + record.sfaSaleContract.revertQuantity
            }
          }
          if(record.sfaSaleContract.alreadyQuantity) {
            str += "已回数量:" + record.sfaSaleContract.alreadyQuantity 
          }
          return str
        } else if(record.sfaSaleContract && record.sfaSaleContract.type ==1){
          return '不需要签订'
        } else{
          return '-'
        }
    },
    getSfaSaleRepayList(record){
      if(record.sfaSaleRepayList && record.sfaSaleRepayList.length > 0) {
        let amount = ""
        record.sfaSaleRepayList.forEach(item=>{
          amount += '¥'+item.amount + ' '
        })
        return amount 
      } else {
        return '-'
      }
    },
    // 格式化年月日
 formatDateNoMin(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day
      return result
    },
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 50, name: value }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerData = res.data.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchChange(searchVal) {
      this.queryData.customerId = []
      this.queryData.customerId = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    findDuplicateIds(array) {
      const idMap = {};
      const duplicates = [];

      array.forEach((item, index) => {
        if (idMap[item.id] === undefined) {
          idMap[item.id] = index;
        } else {
          if (!duplicates.some(duplicate => duplicate.id === item.id)) {
            duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
          } else {
            const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
            existingDuplicate.indexes.push(index);
          }
        }
      });

      return duplicates;
    },
    // 获取值
    getContentcom(data) {
      console.log(data,'data')
      if (data.sfaSaleComList.length > 0) {
        let com = 0;
        data.sfaSaleComList.forEach(item => {
          if(item) {
            com += item.amount;
          }
        })
        if(com > 0) {
          return com
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    // 获取值
    getContent(data) {
      if (data.sfaSaleRepayList && data.sfaSaleRepayList.length > 0) {
        let price = 0;
        data.sfaSaleRepayList.forEach(item => {
          price += item.amount
        })
        if (price > 0 && data.amount - price < 5) {
          return "是";
        } else {
          return "否";
        }
      } else {
        return '否'
      }
    },
    // 格式化数字
    getLocale(amount) {
      if (amount) {
        return amount.toLocaleString('zh-CN');
      } else {
        return 0
      }
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date;
    },
    dateReturnChange(date, dateString) {
      this.queryData.expectReturnTimeMin = dateString[0].toString();
      this.queryData.expectReturnTimeMax = dateString[1].toString();
      this.dateReturnPrice = date;

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.getData()
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    search() {
      this.queryData.page = 0;
      this.getData()
    },
    // 获取列表
    getData() {
      this.tableLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/sale/card/finance/list', 'post', this.queryData).then(res => {
        this.tableLoading = false;
        let data = res.data.data;
        if (res.data.code == 200) {
          data.data.forEach(item => {
            if (!item.sfaSaleContract) {
              item.sfaSaleContract = {}
            }
          })
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })

      let params = JSON.parse(JSON.stringify(this.queryData));
      delete params.page
      delete params.size
      console.log(params, 'params')
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/sale/card/finance/statistics', 'post', params).then(res => {
        this.totalText = res.data.data;
      })
    },
    tabPageChange(page) {
      this.queryData.page = page - 1;
      this.tabCurrent = page;
      this.getData()
    },
    exportTask() {
      this.$message.loading('处理中')
      let params = this.queryData;
      params.remarks = ''
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/exportBrand', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功！')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp, type) {
      if (!timestamp) return '-'
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      // const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
      if (type == 1) {
        result = year + '-' + month + '-' + day
      }
      return result
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
        type: 1,
      }
      this.dateValue = ''
      this.searchVal = []
      this.dateReturnPrice = ''
      this.getData()
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

.spin-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  color: #5542F6;
  margin-top: 10px;
  padding-left: 10px;
}

.spin-content li {

  line-height: 35px;
  // margin-bottom: 10px;
  margin-right: 10px;
  padding-right: 10px;
}
</style>