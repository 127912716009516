<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="销售订单号" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-input v-model="queryData.condition" placeholder="请输入销售订单号" />
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="提交时间" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :value="subValue" :ranges="{
      '今天': [moment(), moment()],
      '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '当月': [moment().startOf('month'), moment().endOf('month')],
      '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }" style="width: 100%;" @change="subChange" />
                </a-row>
              </a-form-item>
            </a-col>


            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="出库时间" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :value="dateValue" :ranges="{
      '今天': [moment(), moment()],
      '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '当月': [moment().startOf('month'), moment().endOf('month')],
      '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }" style="width: 100%;" @change="dateChange" />
                </a-row>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="更新时间" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :ranges="{
      '今天': [moment(), moment()],
      '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '当月': [moment().startOf('month'), moment().endOf('month')],
      '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }" style="width: 100%;" @change="updateChange" :value="updValue" />
                </a-row>
              </a-form-item>
            </a-col>

          </a-row>


          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="分销商" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.distributorCode" @change="codeChange" style="width: 100%"
                  placeholder="请选择分销商" option-label-prop="label">
                  <a-select-option value="20220601001" label="优加惠品">
                    优加惠品
                  </a-select-option>
                  <a-select-option value="20221215001" label="好乐滋">
                    好乐滋
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="卖方主体" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.sellerId" style="width: 100%" placeholder="请选择卖方主体">
                  <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="卡券类型" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.type" style="width: 100%" placeholder="请选择卡券类型"
                  option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for="item in cardType" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>


            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="客户" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select show-search :value="searchVal" placeholder="请输入客户名称" :default-active-first-option="false"
                  :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
                  @change="handleSearchChange">
                  <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="卡券" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.giftId" style="width: 100%" placeholder="请选择卡券"
                  :default-value="null" option-label-prop="label" :filter-option="filterOption">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in giftList" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="介质类型" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.mediaType" style="width: 100%" placeholder="请选择介质类型"
                  option-label-prop="label">
                  <a-select-option value="1" label="电子卡">
                    电子卡
                  </a-select-option>
                  <a-select-option value="2" label="实体卡">
                    实体卡
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="提交状态" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.status" style="width: 100%" placeholder="请选择提交状态"
                  option-label-prop="label">
                  <a-select-option value="1" label="草稿">
                    草稿
                  </a-select-option>
                  <a-select-option value="2" label="已提交审核中">
                    已提交审核中
                  </a-select-option>
                  <a-select-option value="3" label="审核未通过">
                    审核未通过
                  </a-select-option>
                  <a-select-option value="4" label="审核通过">
                    审核通过
                  </a-select-option>
                  <a-select-option value="5" label="已撤销">
                    已撤销
                  </a-select-option>
                  <a-select-option value="6" label="关闭">
                    关闭
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="出库状态" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.outboundStatus" style="width: 100%" placeholder="请选择出库状态"
                  option-label-prop="label">
                  <a-select-option value="0" label="出库中">
                    出库中
                  </a-select-option>
                  <a-select-option value="1" label="出库成功">
                    出库成功
                  </a-select-option>
                  <a-select-option value="2" label="撤销出库">
                    撤销出库
                  </a-select-option>
                  <a-select-option value="3" label="出库驳回">
                    出库驳回
                  </a-select-option>
                  <a-select-option value="4" label="未出库">
                    未出库
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="激活状态" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.actStatus" style="width: 100%" placeholder="请选择激活状态"
                  option-label-prop="label">
                  <a-select-option value="1" label="等待激活">
                    等待激活
                  </a-select-option>
                  <a-select-option value="2" label="激活成功">
                    激活成功
                  </a-select-option>
                  <a-select-option value="3" label="拒绝激活">
                    拒绝激活
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="冻结状态" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.frozenStatus" style="width: 100%" placeholder="请选择冻结状态"
                  option-label-prop="label">
                  <a-select-option value="1" label="正常">
                    正常
                  </a-select-option>
                  <a-select-option value="2" label="冻结">
                    冻结
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">

              <a-form-item label="客户经理" :labelCol="{ span: coSpan }" :wrapperCol="{ span: 16, offset: 1 }">

                <a-select allowClear v-model="queryData.staffIdList" style="width: 100%" placeholder="请选择客户经理"
                  :filter-option="filterOption" :default-value="null" mode="multiple" option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="iptmd" :sm="iptsm">
              <span class="btn-box" style="margin-top: 6px; display: inline-block;">
                <a-col :md="12" :sm="20">
                  <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
                </a-col>
                <a-col :md="12" :sm="20">
                  <a-button type="primary" @click="search">查询</a-button>
                </a-col>
              </span>
            </a-col>
          </a-row>
        </div>
      </a-form>
      <a-row>
        <a-button @click="$router.push({ path: '/sfa_workOrder/addWorkOrder' })" type="primary">新建销售单</a-button>
      </a-row>
    </div>
    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{ queryData.size >
      total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>

    <div style="margin-top: 20px;" v-if="hasPermission('QUERY_SALE_DOCKET')">
      <a-table :columns="columns" :dataSource="dataSource" bordered :loading="tabLoading" :pagination="false"
        :rowKey="(record, index) => index" :scroll="{ x: 1500 }" class="components-table-demo-nested">
        <template slot="submitTime" slot-scope="text">
          <span v-if="text">{{ formatDate(text) }}</span>
          <span v-else>-</span>
        </template>
        <template slot="expectedProcurementTime" slot-scope="text">
          <span v-if="text">{{ formatDate(text) }}</span>
          <span v-else>-</span>
        </template>
        <template slot="createTime" slot-scope="text">
          <span v-if="text">{{ formatDate(text) }}</span>
          <span v-else>-</span>
        </template>
        <template slot="modifyTime" slot-scope="text">
          <span v-if="text">{{ formatDate(text) }}</span>
          <span v-else>-</span>
        </template>
        <template slot="name" slot-scope="text">
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
            {{ text }}
          </a-popover>
        </template>
        <template slot="customerName" slot-scope="text">
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
            {{ text }}
          </a-popover>
        </template>

        <template slot="action" slot-scope="record">
          <div
            style="padding-left: 12px;text-align: left;margin-bottom: 2px;display: flex;flex-wrap: wrap;height: 40px;align-items: center;">
            <!--            1:草稿 2:已提交审核中 3:审核未通过 4:审核通过 5:已撤销 6:关闭-->
            <a style="margin-right: 10px;"
              v-if="(record.status == 1 || record.status == 5) && hasPermission('COPY_SALE_DOCKET') && record.isBelong == 1"
              @click="details(record, 'submit')">提交审核</a>
            <a style="margin-right: 10px;" @click="details(record, 'details')">详情</a>
            <a style="margin-right: 10px;" @click="details(record, 'copy')"
              v-if="hasPermission('COPY_SALE_DOCKET') && record.isBelong == 1">复制</a>
            <a style="margin-right: 10px;"
              v-if="(record.status == 1 || record.status == 3 || record.status == 5) && record.isBelong == 1"
              @click="details(record, 'edit')">编辑</a>
            <span style="margin-right: 10px;" v-if="hasPermission('UPDATE_SALE_DOCKET_STATUS') && record.isBelong == 1">
              <span v-if="(record.status == 2 || record.status == 4)" @click="details(record, 'revoke')">
                <a v-if="record.outStatus != 1" @click="details(record, 'revoke')">撤销</a>
              </span>
            </span>

            <a style="margin-right: 10px;" @click="Printing(record.id, '1')">销售单</a>
            <!-- <a style="margin-right: 10px;" @click="Printing(record.id, '2')">业务支出单</a> -->
            <a v-if="record.invoiceList.length > 0" style="margin-right: 10px;"
              @click="fpChange(record.invoiceList)">发票</a>

            <!-- 0 出库中 1 出库成功 2 撤销出库 3 出库驳回 4 未出库-->
          </div>
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
          @change="handlePageChange" />
      </div>
    </div>

    <a-modal title="选择单号" :visible="fpvisible" @ok="handleFP" @cancel="fpvisible = false">
      <a-radio-group v-model="fpvalue">
        <a-radio :value="item.id" v-for="item in fpList" :key="item.id" style="margin-bottom:8px;">
          发票申请单号：{{ item.code }}</a-radio>
      </a-radio-group>
    </a-modal>

    <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-if="!hasPermission('QUERY_SALE_DOCKET')">
      <a-empty :description="false" />
      无权限查看
    </div>

    <div v-if="printVisit">
      <a-modal :visible="printVisit" :footer="null" width="80%" :closable="false">
        <div class="print-main" id="print">
          <h2 class="print-title">{{ printType == 1 ? '卡券销售单' : '业务支出单' }}</h2>
          <div style="position:absolute;right:10px;top:10px;font-size:16px;text-align:center">
            <p style="font-size:18px">
              <span>销售订单号：</span>
              <span style="color:#333;">{{ printData.code }}</span>
            </p>
            <p>
              <span>{{ printType == 1 ? '创建时间：' : '销售时间：' }}</span>
              <span>{{ formatDateMin(printData.createTime) }}</span>
            </p>
          </div>
          <ul class="print-top">
            <li>
              <span>客户名称：</span>
              <span>{{ printData.customerName }}</span>
            </li>
            <li>
              <span>销售公司：</span>
              <span>{{ printData.sellerName }}</span>
            </li>
            <li v-if="printData.returnCustomerName">
              <span>回款客户公司：</span>
              <span>{{ printData.returnCustomerName }}</span>
            </li>
          </ul>
          <ul class="print-top print-top2">
            <li>
              <span>开票金额：</span>
              <span>{{ printData.invoiceAmount }}</span>
            </li>
            <li>
              <span>M+:</span>
              <span>{{ printData.businessExpenses }}</span>
            </li>
            <li>
              <span>产品售价：</span>
              <span>{{ printData.amount }}</span>
            </li>
            <li>
              <span>平均折扣：</span>
              <span>{{ printData.averageDiscount }}</span>
            </li>
            <li v-if="printData.sfaSaleContract && printData.sfaSaleContract.type">
              <span>合同：</span>
              <span>{{ printData.sfaSaleContract.type == 2 ? '需要签订' : '无需签订' }}</span>
            </li>
            <li v-if="printData.sfaSaleContract && printData.sfaSaleContract.type == 2">
              <span>合同份数：</span>
              <span>{{ printData.sfaSaleContract.quantity }}</span>
            </li>
            <li v-if="printData.sfaSaleContract && printData.sfaSaleContract.type == 2">
              <span>回合同份数：</span>
              <span>{{ printData.sfaSaleContract.revertQuantity }}</span>
            </li>
            <li v-if="printData.sfaSaleContract && printData.sfaSaleContract.type == 1">
              <span>备注：</span>
              <span>{{ printData.sfaSaleContract.notes }}</span>
            </li>
            <li v-if="printData.expectReturnTime">
              <span>预计回款时间：</span><span>{{ formatDate(printData.expectReturnTime) }}</span>
            </li>
          </ul>

          <div v-for="fa in printData.sfaSaleDocketProductList" :key="fa.id" class="print-table">
            <a-card v-if="fa.sfaCardCouponList.length > 0"
              :title="fa.type == 3 ? '储值卡券' : fa.type == 4 ? '提货券' : fa.type == 1 ? '次票' : ''" :bordered="false">
              <div style="margin-top: 10px;" v-for="faSon in fa.sfaCardCouponList" :key="faSon">
                <a-table :columns="printColumns" bordered :dataSource="[faSon]" :pagination="false" size="middle">
                  <template slot="distributorCode" slot-scope="record">
                    <span v-if="record == '20220601001'">优加惠品</span>
                    <span v-if="record == '20221212001'">好乐滋</span>
                  </template>

                  <template slot="startTime" slot-scope="record,text">
                    <div>
                      <p>{{ formatDate(text.startTime) }}</p>
                      <p>{{ formatDate(text.endTime) }}</p>
                    </div>
                  </template>

                  <template slot="mediaType" slot-scope="record">
                    <span v-if="record == '1'">电子卡</span>
                    <span v-if="record == '2'">实体卡</span>
                  </template>
                  <template slot="discount" slot-scope="record">
                    <span>{{ record }}</span>
                  </template>
                  <template slot="customized" slot-scope="record">
                    <span v-if="record">是</span>
                    <span v-else>否</span>
                  </template>

                  <span slot="amountRechargeTitle">
                    <span v-if="fa.type == 3">单张充值</span>
                    <span v-if="fa.type == 4">档位市场价值</span>
                    <span v-if="fa.type == 1">单张充值</span>
                  </span>

                  <!-- <span slot="incrementAmount" style="font-weight:700;">
                    增值服务抵扣
                  </span> -->

                  <span slot="amountRecharge" slot-scope="record">
                    {{ record.amountRecharge }}<span v-if="record.gearPositionName">({{ record.gearPosition == 9 ?
      '定制档' : '通用档' }})</span>
                  </span>

                  <template slot="incrementAmount" slot-scope="record">
                    <span v-for="(it, menuIndex) in record" :key="menuIndex">
                      {{ it.incrementAmount }}
                    </span>
                  </template>

                  <template slot="isDelay" slot-scope="record,text">
                    <span v-if="text.isDelay == 1">允许延期</span>
                    <span v-if="text.isDelay == 2">不允许延期</span>
                  </template>

                  <template slot="sfaCardCouponMenuList" slot-scope="record">
                    <span v-for="(it, menuIndex) in record" :key="menuIndex">{{ getMenuName(it.menu) }};</span>
                  </template>

                  <template slot="menuRemarks" slot-scope="record">
                    <p v-for="(it, reIndex) in record" :key="reIndex">
                      <span v-if="it.remarks">
                        {{ getMenuName(it.menu) }}:{{ it.remarks }} ;
                      </span>
                    </p>
                  </template>
                </a-table>
                
                <div v-if="faSon.remarksList.length > 0">
                  <a-table style="margin-top:20px;" :columns="lmRemarkColumns" bordered
                  :dataSource="faSon.remarksList" :pagination="false" size="middle">
                  <template slot="menu" slot-scope="record,text">
                    {{ getMenuName(text.menu) }}
                  </template>
                  <template slot="remarks" slot-scope="record,text">
                    <span v-if="text.remarks">{{ text.remarks }}</span>
                  </template>
                </a-table>
                </div>
                <div style="width: 100%;margin-top: 20px; padding: 0 20px;box-sizing: border-box;">
                  <span>开通栏目:</span>
                  
                  <span v-for="faLM in faSon.sfaCardCouponMenuList" :key="faLM.menu">{{ getMenuName(faLM.menu)
                    }};</span>
                </div>
                <div style="display: flex;margin-top: 20px; padding: 0 20px;box-sizing: border-box;">
                  <div style="flex: 1;display: flex;margin-right: 20px;">
                    <div style="margin-right: 10px;width: 50px;">
                      <span style="font-size: 15px;">备注:</span>
                    </div>

                    <div style="width: 100%;">
                      <!-- <span>{{ fa.remarks }}</span> -->
                      <span v-if="fa.remarks">{{ fa.remarks }}</span>
                      <span v-else>无备注</span>
                    </div>
                  </div>
                </div>

                <a-divider />
              </div>

            </a-card>
          </div>

          <div class="print-btm">
            <p>有效业绩：</p>
            <p>提成金额：</p>
            <p>是否为合并项目：</p>
            <p></p>
            <p></p>
          </div>

          <div class="print-btm">
            <p>客户经理：{{ printData.salespersonName }}</p>
            <p>销售主管：</p>
            <p>销售总监：</p>
            <p>销售负责人：</p>
            <p>领票人：</p>
          </div>
        </div>

        <div class="print-btn no-print">
          <a-button type="primary" style="margin-right: 10px;" v-print="printObj">打印预览</a-button>
          <a-button @click="printVisit = false">关闭</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce';

const renderContent = (value) => {
  const obj = {
    children: value,
    attrs: {},
  };
  return obj;
};

export default {
  name: 'OutboundList',
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      columns: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          customRender: (value, row, index) => {
            value = this.formatDate(value);
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 150
        },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          customRender: (value, row, index) => {
            if (value) {
              value = this.formatDate(value);
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 150,
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          // scopedSlots: { customRender: 'customerName' },
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          // ellipsis: true,
          align: 'center',
          width: 150
        },
        {
          title: '开票金额',
          dataIndex: 'invoiceAmount',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          ellipsis: true,
          width: 150
        },
        {
          title: '可开票金额',
          dataIndex: 'surplusAmount',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          ellipsis: true,
          width: 150
        },
        {
          title: '已开票金额',
          dataIndex: 'alreadyAmount',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          ellipsis: true,
          width: 150
        },
        {
          title: 'M+',
          // title: '业务支出',
          dataIndex: 'businessExpenses',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 150
        },
        {
          title: '提交状态',
          dataIndex: 'statusName',
          scopedSlots: { customRender: 'statusName' },
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 150
        },
        {
          title: '客户经理',
          dataIndex: 'staffName',
          // customRender: renderContent,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 150
        },


        {
          title: '最后更新时间',
          dataIndex: 'modifyTime',
          customRender: (value, row, index) => {
            if (value) {
              value = this.formatDate(value);
            } else {
              value = '-'
            }
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 150
        },
        {
          title: '销售订单号',
          dataIndex: 'code',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            let result = this.findDuplicateIds(this.dataSource);
            result.forEach(item => {
              if (item.id == row.id) {
                obj.attrs.rowSpan = 0;
                if (index == item.indexes[0]) {
                  obj.attrs.rowSpan = item.indexes.length;
                }
              }
            })
            return obj;
          },
          align: 'center',
          width: 220
        },

        {
          title: '出库状态',
          dataIndex: 'outStatusName',
          customRender: renderContent,
          align: 'center',
          width: 150
        },
        {
          title: '激活状态',
          dataIndex: 'actStatusName',
          customRender: renderContent,
          align: 'center',
          width: 150
        },
        {
          title: '允许延期',
          dataIndex: 'isDelayName',
          customRender: renderContent,
          align: 'center',
          width: 150
        },
        {
          title: '冻结状态',
          dataIndex: 'frozenStatusName',
          customRender: renderContent,
          align: 'center',
          width: 150
        },
        {
          title: '出库批次号',
          dataIndex: 'outBatchCode',
          customRender: renderContent,
          align: 'center',
          width: 220
          // ellipsis: true,
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          width: 200,
          // customRender: (value, row, index) => {
          //   const obj = {
          //     children: value,
          //     attrs: {},
          //   };
          //   let result = this.findDuplicateIds(this.dataSource);
          //   result.forEach(item=>{
          //       if(item.id == row.id) {
          //         obj.attrs.rowSpan = 0;                       
          //       if(index == item.indexes[0]) {
          //         obj.attrs.rowSpan = item.indexes.length;
          //       }
          //     }
          //   })
          //   return obj;
          // },
          align: 'center',
          fixed: 'right',
        }
      ],
      lmRemarkColumns: [{
        title: '备注栏目',
        dataIndex: 'menu',
        scopedSlots: { customRender: 'menu' },
        width: '20%',
        align: 'center',
      }, {
        title: '备注',
        dataIndex: 'remarks',
        scopedSlots: { customRender: 'remarks' },
        align: 'center',
      }],
      printColumns: [
        {
          title: '分销商',
          dataIndex: 'distributorCode',
          scopedSlots: { customRender: 'distributorCode' },
          align: 'center',
          width: 110
        },
        {
          title: '礼品卡名称',
          dataIndex: 'giftName',
          scopedSlots: { customRender: 'giftName' },
          align: 'center',
          width: 120
        },
        {
          title: '是否定制卡券',
          dataIndex: 'customized',
          scopedSlots: { customRender: 'customized' },
          align: 'center',
          width: 110
        },
        {
          title: '介质类型',
          dataIndex: 'mediaType',
          align: 'center',
          scopedSlots: { customRender: 'mediaType' },
          width: 110
        },
        {
          title: '数量',
          dataIndex: 'number',
          align: 'center',
          width: 80
        },
        {
          // title: '档位市场价值',
          // dataIndex: 'amountRecharge',
          slots: { title: 'amountRechargeTitle' },
          scopedSlots: { customRender: 'amountRecharge' },
          align: 'center',
          width: 110
          // width:130
        },

        // {
        //   // title: '',
        //   dataIndex: 'sfaCardCouponMenuList',
        //   slots: { title: 'incrementAmount' },
        //   scopedSlots: { customRender: 'incrementAmount' },
        //   align: 'center',
        // },
        {
          title: '单张实收',
          dataIndex: 'amount',
          align: 'center',
          width: 130
        },
        {
          title: '有效期',
          dataIndex: 'startTime',
          align: 'center',
          scopedSlots: { customRender: 'startTime' },
          width: 140
        },
        {
          title: '延期',
          dataIndex: 'isDelayName',
          align: 'center',
          width: 80
          // scopedSlots: {customRender: 'isDelay'},
        },
        // {
        //   title: '开通栏目',
        //   dataIndex: 'sfaCardCouponMenuList',
        //   align: 'center',
        //   scopedSlots: { customRender: 'sfaCardCouponMenuList' },
        //   width: 260
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'sfaCardCouponMenuList',
        //   scopedSlots: { customRender: 'menuRemarks' },
        //   align: 'center',
        //   // width: 300
        // }
      ],
      searchVal: [],
      fpvisible: false,
      printObj: {
        id: "print", //要打印的id名 无#号
        extraCss: "", // 打印可引入外部的一个 css 文件
        popTitle: "&nbsp;", //打印的页眉标题，默认浏览器标题 空字符串时显示undefined 使用html语言
        extraHead: "", //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
        preview: "", // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
        previewTitle: "", // 打印预览的标题（开启预览模式后出现）,
        previewPrintBtnLabel: "", // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
        zIndex: "", // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
        previewBeforeOpenCallback() { }, //预览窗口打开之前的callback（开启预览模式调用）
        previewOpenCallback() { }, // 预览窗口打开之后的callback（开启预览模式调用）
        beforeOpenCallback() { }, // 开启打印前的回调事件
        openCallback() { }, // 调用打印之后的回调事件
        closeCallback() { }, //关闭打印的回调事件（无法确定点击的是确认还是取消）
        url: "",
        standard: "",
      },
      iptmd: 5,
      iptsm: 22,
      coSpan: 6,
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      dataSource: [],
      tabLoading: false,
      total: 0,

      // 筛选
      queryData: {
        page: 0,
        size: 10,
      },
      currentPage: 1,
      cardType: [{
        id: 1,
        name: '次票'
      }, {
        id: 3,
        name: '储值卡'
      }, {
        id: 4,
        name: '礼品兑换卡'
      }],
      distributorList: [],// 分销商
      sellerList: [],//卖方主体
      giftList: [],//卡券
      updValue: "",
      subValue: "",
      dateValue: "",

      printVisit: false,//打印弹窗
      printData: null,
      printType: 0,
      fpList: [],
      fpvalue: ""
    }
  },
  watch: {
    $route() {
      this.getData();
      this.initData(); //初始化数据
    },
  },
  mounted() {
    this.getData();
    this.initData(); //初始化数据
  },
  computed: {
    ...mapGetters('account', ['user']),
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    },
    scrollX() {
      return this.columns.reduce((preVal, curVal) => {
        return preVal + curVal.width;
      }, 0);
    },

  },
  methods: {
    getMenuName(menu) {
      let name = "";
      switch (menu) {
        case 1:
          name = "商城"
          break;
        case 2:
          name = "电影"
          break;
        case 3:
          name = "蛋糕"
          break;
        case 4:
          name = "礼包兑换"
          break;
        case 5:
          name = "生活"
          break;
        case 6:
          name = "储值卡购买礼包商品"
          break;
        case 7:
          name = "演出"
          break;
        case 8:
          name = "线下门店"
          break;
        case 9:
          name = "图书"
          break;
        case 10:
          name = "景点门票"
          break;
        case 11:
          name = "点餐"
          break;
      }
      return name;
    },
    fpChange(record) {
      this.fpList = record;
      this.fpvisible = true;
    },
    handleFP() {
      if (this.fpvalue) {
        this.$router.push({ path: '/sfa_workOrder/editInvoiceApply', query: { id: this.fpvalue, isDetail: true } });
        this.fpvisible = false;
      }
    },
    // 打印
    Printing(id, type) {
      this.printVisit = true;
      this.printType = type;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/' + id, 'get').then(res => {
        this.printData = res.data.data;
        this.printData.sfaSaleDocketProductList.forEach(item => {
          if (item.sfaCardCouponList.length > 0) {
            item.sfaCardCouponList.forEach(item2 => {
              if (item2.sfaCardCouponMenuList.length > 0) {
                // 使用 filter 来移除没有 remarks 的元素
                item2.remarksList = []

                console.log(item2.sfaCardCouponMenuList,'item2.sfaCardCouponMenuList')
                item2.remarksList = item2.sfaCardCouponMenuList.filter(item3 => item3.remarks);
              }
            });
          }
        });
        console.log(this.printData,'this.printData')
        if (this.printData.length > 1) {
          var order = [3, 4, 1];
          this.printData.sort((a, b) => {
            const typeA = a.type;
            const typeB = b.type;
            const indexA = order.indexOf(typeA);
            const indexB = order.indexOf(typeB);
            return indexA - indexB;
          });
        }
      })
    },
    findDuplicateIds(array) {
      const idMap = {};
      const duplicates = [];

      array.forEach((item, index) => {
        if (idMap[item.id] === undefined) {
          idMap[item.id] = index;
        } else {
          if (!duplicates.some(duplicate => duplicate.id === item.id)) {
            duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
          } else {
            const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
            existingDuplicate.indexes.push(index);
          }
        }
      });

      return duplicates;
    },
    codeChange(e) {
      this.giftList = []
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/gift/type/list/gift/' + e, 'get').then(res => {
        res.data.data.forEach(item => {
          item.giftList.forEach(it => {
            this.giftList.push(it)
          })
        })
      })
    },
    search() {
      this.queryData.page = 0;
      this.getData();
    },
    initData() {
      // 分销商
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/distributor/list', 'get').then(res => {
        this.distributorList = res.data.data;
      })
      // 卖方主体
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
      })
      // 卡券
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/gift/type/list/gift/20220601001', 'get').then(res => {
        this.giftList = []
        res.data.data.forEach(item => {
          item.giftList.forEach(it => {
            this.giftList.push(it)
          })
        })
      })
      // 客户经理
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
        this.userData = res.data.data
      })
      // 客户列表
      // request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
      //   this.customerData = res.data.data
      // })
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 50, name: value }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerData = res.data.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchChange(searchVal) {
      this.queryData.customerId = []
      this.queryData.customerId = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    // 产品
    details(record, type) {
      // type 1详情
      if (type == 'details') {
        this.$router.push({
          path: '/sfa_workOrder/detailsWorkOrder',
          query: { id: record.id }
        });
      }
      // 编辑
      if (type == 'edit') {
        this.$router.push({
          path: '/sfa_workOrder/editWorkOrder',
          query: { id: record.id }
        });
      }
      // 提交/撤销
      if (type == 'submit' || type == 'revoke') {
        let status, msg;
        status = 4;
        msg = '提交成功！'
        if (type == 'revoke') { //撤销
          status = 5;
          msg = '撤销成功！'
        }
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/updatestatus', 'post', {
          id: record.id, status: status
        }).then(res => {
          if (res.data.code == 200) {
            this.$message.destroy()
            this.$message.success(msg)
            this.getData();
          }
        })
      }
      // 删除
      if (type == 'del') {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/' + record.id, 'delete').then(res => {
          if (res.data.code == 200) {
            this.$message.destroy()
            this.$message.success('删除成功！')
            this.getData();
          }
        })
      }
      // 复制
      if (type == 'copy') {
        this.$router.push({
          path: '/sfa_workOrder/editWorkOrder',
          query: { id: record.id, pageType: 'copy' }
        });
      }

    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    formatDateMin(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      return result;
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.outboundStartTime = dateString[0].toString();
      this.queryData.outboundEndTime = dateString[1].toString();
      this.dateValue = date
    },
    subChange(date, dateString) {
      this.queryData.submitStartTime = dateString[0].toString();
      this.queryData.submitEndTime = dateString[1].toString();
      this.subValue = date;
    },
    updateChange(date, dateString) {
      this.queryData.modifyStartTime = dateString[0].toString();
      this.queryData.modifyEndTime = dateString[1].toString();
      this.updValue = date
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
        // staffId:'',
        // dealStatus: '',//成交状态
        // type: '',//
      }
      this.searchVal = []
      this.subValue = ''
      this.dateValue = ''
      this.updValue = ''
      this.getData()
    },
    // 获取列表 
    getData() {
      this.tabLoading = true;
      let params = this.queryData;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/list', 'post', params).then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/' + e.id, 'delete').then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.EditVisible = false;
          this.getData()
        }
      })
    },

    remove() {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      this.$message.info('您清空了勾选的所有行')
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}



@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}

p {
  margin: 0;
}

.print-main ::v-deep .ant-card-body {
  // padding: 12px !important;
}

.print-main ::v-deep .ant-card-head {
  border: 0 !important;
  min-height: 0 !important;
  // padding: 0 12px !important;
}

.print-main ::v-deep .ant-card-head-title {
  padding: 0 !important;
  font-size: 18px;
}

.print-btn {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
 ::v-deep .ant-table-column-title {
    font-size: 14px !important;
 }
 ::v-deep .ant-table-row-cell-break-word {
  font-size: 14px !important;
 }
.print-title {
  text-align: center;
  font-size: 26px;
  margin: 0 0 40px 0;

}

.print-table {
  margin-bottom: 30px;
}

.print-top {
  display: flex;
  // justify-content: center;
  color: #000;
  font-weight: 500;
  flex-wrap: wrap;
  padding: 0 12px;
  justify-content: left;
  box-sizing: border-box;
}

.print-top li {
  width: 33.33%;
  text-align:left;
  margin-bottom: 16px;
  font-size: 16px;
  padding-right: 10px;
  box-sizing: border-box;
}

.print-top span {
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

.print-top2 {

}

.print-top2 li {
  width: 24%;
}

.print-main {
  width: 100%;
  margin: auto;
  height: 100%;
}

.print-btm {
  display: flex;
  padding: 10px;
  font-size: 16px;
  margin-top: 40px;
  height: 100px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
}

.print-btm p {
  flex: 1;
  color: #000;
}

.print-table ::v-deep .ant-card {
  font-size: 16px;
  color: #000 !important;
  font-weight: 500;
}

.print-table ::v-deep .ant-table {
  font-size: 16px;
  color: #000 !important;
  font-weight: 500;
}

.print-main ::v-deep .ant-card-head {
  border: 0 !important;
  min-height: 0 !important;
  padding: 0 12px !important;
  box-sizing: border-box;
}

/* 自定义表格线颜色 */
.print-main ::v-deep .ant-table-tbody>tr>td {
  border-color: #333;
  /* 设置表格单元格的边框颜色 */
}

/* 可选：设置表格头部线颜色 */
.print-main ::v-deep .ant-table-thead>tr>th {
  border-color: #333;
  /* 设置表格头部单元格的边框颜色 */
}

/* 可选：设置表格外边框颜色 */
.print-main ::v-deep .ant-table {
  border-color: #000;
  /* 设置表格的外边框颜色 */
}

.print-main ::v-deep .ant-table-bordered .ant-table-body>table {
  border-color: #333;
  /* 设置表格的外边框颜色 */
}

.print-main table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-spacing: 0;

}

.print-main table {
  width: 100%;
}

.print-main tr {
  width: 150px;
  height: 60px;
  border-bottom: 1px solid #000;
  padding: 8px 10px;
  box-sizing: border-box;
  border-right: 1px solid #000;
  line-height: 20px;
  text-align: center;
}

.print-main td {
  width: 150px;
  height: 60px;
  border-bottom: 1px solid #000;
  padding: 8px 10px;
  box-sizing: border-box;
  border-right: 1px solid #000;
  line-height: 20px;
  text-align: center;
}
</style>
<!-- 打印样式表 -->
<style media="print">
/* 设置打印时的样式 */
@media print {

  /* 隐藏不需要打印的元素 */
  .no-print {
    display: none;
  }

  .print-main {
    zoom: 65%;
    page-break-after: always;
    padding: 30px 0;
    box-sizing: border-box;
  }

  .ant-table {
    /* page-break-inside: avoid; */
  }

  /* 隐藏日期和网址 */
  @page {
    margin-top: 8mm;
  }

}
</style>