<template>
  <div class="page">
    <div class="inv-box">
      <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
        <div class="ant-descriptions-title">买方信息</div>
        <div style="display: flex;" class="inv-box">
          <a-form-item required label="客户" style="width: 300px">
            <a-select v-model="queryData.customerId" show-search
              :filter-option="(input, option) => { return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0); }"
              style="width: 100%" placeholder="请选择客户" :default-value="null" :disabled="isStatus"
              option-label-prop="label" @change="selectChange">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in customerData" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item required label="发票抬头" style="width: 300px;margin-left:10px;" v-if="invoiceList.length > 0">
            <a-select v-model="queryData.invoiceId" show-search
              :filter-option="(input, option) => { return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0); }"
              style="width: 100%" placeholder="请选择发票抬头" :disabled="isStatus" :default-value="null"
              option-label-prop="label" @change="selectInvoiceChange">
              <a-select-option :value="item.id" :label="item.invoiceHeading" v-for="(item, index) in invoiceList"
                :key="index">
                {{ item.invoiceHeading }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <div v-if="invoiceList.length == 0 && queryData.customerId">
            <a-icon type="warning" theme="filled" style="color:red;" />
            当前客户暂无开票信息，请在我的客户中进行添加开票信息<a href="#" style="border-bottom:1px solid;"
              @click="$router.push('/sfa_customer/sfa_customerMineList')">前往添加</a>
          </div>
        </div>

        <div style="width:100%;display: flex;flex-wrap:wrap;">
          <a-form-model-item label="公司名称" required style="width: 22%;">
            <span>{{ selectInvoice.invoiceHeading }}</span>
          </a-form-model-item>
          <a-form-model-item label="发票抬头" required style="width: 22%;">
            <span>{{ selectInvoice.invoiceHeading }}</span>
          </a-form-model-item>
          <a-form-model-item label="统一社会信用代码/纳税人识别号" required style="width: 22%;">
            <span>{{ selectInvoice.dutyParagraph }}</span>
          </a-form-model-item>
          <a-form-model-item label="开户行" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ selectInvoice.bank }}</span>
          </a-form-model-item>
          <a-form-model-item label="发票类型" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ selectInvoice.invoiceTypeName }}</span>
          </a-form-model-item>
          <a-form-model-item label="账号" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ selectInvoice.bankAccount }}</span>
          </a-form-model-item>
          <a-form-model-item label="地址" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ selectInvoice.address }}</span>
          </a-form-model-item>
          <a-form-model-item label="电话" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ selectInvoice.phone }}</span>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <ul>
      <li>
        <!-- 开票信息 -->
        <a-form-model style="width:100%;border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
          <div class="ant-descriptions-title">销售方信息</div>
          <div style="display: flex;">
            <a-form-model-item label="销售方信息" required>
              <div style="position: relative;">
                <a-select v-model="form.sellerId" @change="checkoutSeller" style="width: 300px" placeholder="请选择销售方信息"
                  :disabled="isStatus">
                  <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-model-item>

            <a-form-model-item label="发票类型" required style="margin:0 10px;">
              <div style="position: relative;">
                <a-select :disabled="isStatus" v-model="form.invoiceTypeId" style="width: 300px" placeholder="请选择发票类型">
                  <a-select-option :value="item.id" v-for="item in TypeList" :key="item.id"
                    @click="invoiceTypeChange(item.invoiceType)">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-model-item>

          </div>
        </a-form-model>


        <!-- 开票明细  非关联-->
        <infoTable v-if="!isStatus" :data="sfaInvoiceApplyDetailsParamList" :customerId="queryData.customerId"
          :sellerId="this.form.sellerId" @sendCententList="sendCententList"></infoTable>
        <!-- 关联 -->
        <infoTableGL v-if="isStatus" @getDetails="getDetails" :data="sfaInvoiceApplyDetailsParamList" :customerId="queryData.customerId"
          :sellerId="this.form.sellerId" @sendCententList="sendCententList"></infoTableGL>

          <p style="font-weight: 700; color: rgb(0, 0, 0); margin-top: 10px;" v-if="this.form.actualAmount">合计开票金额: <span style="color:red;">{{this.form.actualAmount}}</span> </p>
        <!-- 备注 -->
        <a-form-item label="发票备注">
          <a-textarea v-model="form.remarks" :disabled="$route.query.isDetail || isStatus"
            :placeholder="$route.query.isDetail ? '-' : '1、该备注信息会展示在发票上展示，如有需要请录入，不需要则无需填写！2、建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到！'"></a-textarea>
        </a-form-item>
        <!-- 说明 -->
        <div class="remarks-box">
          <a-form-item label="开票申请说明">
            <a-textarea :disabled="$route.query.isDetail || isStatus" v-model="form.applyExplain"
              placeholder="仅应用于内部审批备注，发票不展示！"></a-textarea>
          </a-form-item>
        </div>
        <div style="display: flex; justify-content: right;align-items: center;" class="info-two">
          <!-- 新增 预开票 -->
          <div style="display: flex;">
            <a-button type="primary" @click="save" style="margin-left: 10px;">提交</a-button>
          </div>

        </div>
      </li>
    </ul>

  </div>
</template>
<script>
import { request } from "@/utils/request";
import infoTable from './components/invoiceInfoTable.vue'
import infoTableGL from './components/invoiceInfoTableGL.vue'
import { EventBus } from '@/utils/event-bus.js';

export default {
  components: { infoTable, infoTableGL },
  data() {
    return {

      customerData: [],//客户列表
      invoiceList: [],//发票     
      queryData: {
        page: 0,
        size: 20,
        // f5b8a7ae-2bf6-4265-bb73-817ad6ebc17b
      },

      sellerList: [],//主体公司

      selectInvoice: {},//选中的发票信息
      TypeList: [],
      // 提交表单
      form: {
        amount: 0,//开票金额
        sfaInvoiceApplyDetailsList: [],
      },
      tempSfaInvoiceApplyDetailsList: [],//接收子组件传来的sfaInvoiceApplyDetailsList
      invoiceType: 0,//发票类型
      invoiceAmount: 0,//可开票金额
      glAmout: '',//关联开票金额
      sfaInvoiceApplyDetailsParamList: [],
      invoicingType: 1,
      EditSfaInvoiceApplyDetailsList: [],
      isStatus: false,
    };
  },
  mounted() {
    this.getUser();//客户列表
    this.getSellerList();//主体公司
    // 详情
    this.getDetails();
  },
  watch: {
    'form.sellerId': function (newVal, oldValue) {
      console.log(newVal, oldValue, 'newVal');
      this.getInoviceTypeList();
    }
  },
  methods: {
    closePage() {
      EventBus.$emit('itemRemoved');
    },
    getDetails() {
      if (this.$route.query.id) {
        // 关联
        if (this.$route.query.status) {
          this.isStatus = true
        } else {
          this.isStatus = false
        }
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/' + this.$route.query.id, 'get').then(res => {
          let data = res.data.data;

          // 修改数组名和对象中的属性名
          this.form = {
            ...data,
            sfaInvoiceApplyDetailsParamList: data.sfaInvoiceApplyDetailsContentList.map(item => {
              const { sfaInvoiceApplyContentList, ...rest } = item;  // 解构获取 b 和其他属性
              return { ...rest, sfaInvoiceApplyDetailsParamList: sfaInvoiceApplyContentList };     // 重命名 b 为 d
            })
          };

          delete this.form.sfaInvoiceApplyDetailsContentList;  // 删除原来的 a 属性


          this.form.sfaInvoiceApplyDetailsParamList.forEach(item => {
            if (!item.sfaInvoiceApplyDetailsParamList || item.sfaInvoiceApplyDetailsParamList.length == 0) {
              item.code = [{
                code: ""
              }]
            } else {
              item.code = []
              item.sfaInvoiceApplyDetailsParamList.forEach(it => {
                item.code.push({ code: it.detailCode, money: it.money, invoicingType: it.invoicingType, surplusAmount: it.surplusAmount })
              })
            }
          })
          this.form.applayId = res.data.data.id
          // 发票信息
          this.selectInvoice = this.form.sfaInvoice;
          this.queryData.customerId = this.form.customerId;
          this.queryData.invoiceId = this.form.invoiceId;
          // 开票信息
          this.sfaInvoiceApplyDetailsParamList = this.form.sfaInvoiceApplyDetailsParamList
          // 获取该客户发票列表
          this.getInoviceList();
          // 关联
          if (this.$route.query.status) {
            this.invoicingType = 1;
            setTimeout(() => {
              this.selectInvoiceChange(this.form.invoiceId);
            }, 1500)
          }
        });
      }
    },
    invoiceTypeChange(type) {
      this.invoiceType = type;
    },

    checkoutSeller(e) {
      this.form.sellerId = e;
      delete this.form.invoiceTypeId
      this.getInoviceTypeList();
    },

    checkEditable(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].editable) {
          this.$message.warning('请保存发票明细！')
          return false;
        }
      }
      return true;
    },
    save() {
      this.form.customerId = this.queryData.customerId; //客户id
      this.form.invoiceType = this.selectInvoice.invoiceType;//专票/普票、
      this.form.invoiceId = this.queryData.invoiceId;
      if (!this.form.invoiceTypeId) {
        this.$message.warning('请选择发票类型！')
        return false;
      }
      if (!this.checkEditable(this.sfaInvoiceApplyDetailsParamList)) {
        return false;
      }
      // 专票情况下 判断每个发票信息是否为空
      if (this.invoiceType == 2) {
        if (!this.selectInvoice.invoiceHeading || !this.selectInvoice.dutyParagraph || !this.selectInvoice.bank || !this.selectInvoice.invoiceTypeName || !this.selectInvoice.bankAccount || !this.selectInvoice.address || !this.selectInvoice.phone) {
          this.$message.warning('发票信息不全，需将发票信息中标记*号的字段补齐后可进行提交！')
          return false;
        }
      }
      this.form.invoiceId = this.queryData.invoiceId;
      this.form.type = 1; //1申请单 2预开票
      // 预开票
      if (this.invoicingType == 3) {
        this.form.type = 2;
      }
      // 新增 
      if (!this.$route.query.status && !this.$route.query.id) {
        this.addSave();
      }
      // 编辑
      if (this.$route.query.id && !this.$route.query.status) {
        if (this.form.actualAmount > this.form.amount && this.invoicingType != 3) {
          this.$message.error('明细合计开票金额已大于可开票金额，无法提交！')
          return false;
        }
        this.editSave();
      }
      // 关联
      if (this.$route.query.status) {
        let that = this;
        this.$confirm({
          title: '预开票关联销售单仅可关联一次，请确认预开票金额等于销售单可开票金额！',
          onOk() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/correlationReceipt/' + that.form.id, 'post', that.form).then(res => {
              if (res.data.code == 200) {
                that.$message.success('操作成功！');
                that.closePage();
                that.$router.push('/sfa_workOrder/InvoiceApplyList')
              } else {
                that.$message.error(res.data.message);
              }
            })
          }
        })
      }
    },
    // 编辑
    editSave() {
      if (this.form.sfaInvoiceApplyDetailsParamList && this.form.sfaInvoiceApplyDetailsParamList.length > 0) {
        this.form.sfaInvoiceApplyDetailsParamList.forEach(item => {
          delete item.id
          delete item.applyId
          item.sfaInvoiceApplyDetailsParamList.forEach(it => {
            delete it.id
            delete it.applyId
          })

        })
      }
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/update', 'post', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！');
          this.closePage();
          this.$router.push('/sfa_workOrder/InvoiceApplyList')
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    addSave() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/save', 'post', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！');
          this.closePage();
          this.$router.push('/sfa_workOrder/InvoiceApplyList')
        } else {
          this.$message.error(res.data.message);
        }
      })
    },

    selectChange() {
      delete this.queryData.invoiceId;
      //获取该客户发票列表
      this.getInoviceList();
      this.getSellerList();//获取卖方主体
      this.form.sfaInvoiceApplyDetailsList = []
      this.sfaInvoiceApplyDetailsParamList = []
      this.selectInvoice = {}
    },
    selectInvoiceChange(id) {
      this.invoiceList.forEach(item => {
        if (id == item.id) {
          this.selectInvoice = item;
        }
      })
    },
    // 开票详情数组
    sendCententList(data) {
      // 非关联
      if (!this.$route.query.status) {
        // 合计可开票金额
        this.form.amount = 0;
        data.forEach(item => {
          item.sfaInvoiceApplyDetailsParamList = []
          item.code.forEach(it => {
            this.invoicingType = it.invoicingType;
            if (it.code) {
              this.form.amount += it.surplusAmount - 0;
              let obj = {
                detailCode: it.code,
                invoicingType: it.invoicingType,
                money: it.money
              }
              item.sfaInvoiceApplyDetailsParamList.push(obj)
            }
          })
        })
        this.form.sfaInvoiceApplyDetailsParamList = data;
        // 实际开票金额
        this.form.actualAmount = 0;
        this.sfaInvoiceApplyDetailsParamList = data;
        data.forEach(item => {
          this.form.actualAmount = this.form.actualAmount + (item.money - 0)
        })
      } else {
        console.log(data, 'data---')
        data.forEach(item => {
          item.sfaInvoiceApplyDetailsParamList = []
          item.code.forEach(it => {
            this.invoicingType = it.invoicingType;
            if (it.code) {
              this.form.amount += it.surplusAmount - 0;
              let obj = {
                detailCode: it.code,
                invoicingType: it.invoicingType,
                money: it.money
              }
              item.sfaInvoiceApplyDetailsParamList.push(obj)
            }
          })
        })
        this.form.sfaInvoiceApplyDetailsParamList = data;
      }

    },

    // 卖方主体
    getSellerList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
        this.form.sellerId = this.sellerList[0].id
        this.getInoviceTypeList()
      })
    },
    // 获取该公司发票种类
    getInoviceTypeList() {
      if (this.form.sellerId) {
        request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/type/list/" + this.form.sellerId, "get").then((res) => {
          if (res.data.code == 200) {
            this.TypeList = res.data.data;
          }
        })
      }
    },
    // 获取用户发票列表
    getInoviceList() {
      request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/list/" + this.queryData.customerId, "get").then((res) => {
        this.invoiceList = res.data.data;
      });
    },
    getUser() {
      // 客户列表
      request(process.env.VUE_APP_API_BASE_URL + "sfa/customer/list/all", "get").then((res) => {
        this.customerData = res.data.data;
      });
    },

  },
};
</script>
<style scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 140px);
  /* display: flex; */
  /* overflow: hidden; */
  padding: 20px;
  box-sizing: border-box;
}

.page ul {
  padding: 0;
}

.page-table {}

.page li {
  height: 100%;
}




.page-box {
  margin-left: auto;
  padding-right: 46px;
  margin-top: 10px;
}

.sale-list {
  display: flex;
  font-size: 15px;
  font-weight: 500;
}

.sale-list p {
  margin-right: 20px;
}

.page-info {
  position: absolute;
  right: 10px;
  top: 120px;
  z-index: 99999;
  padding-right: 20px;
}

.inv-box ::v-deep .ant-form-item-label>label {
  color: rgba(0, 0, 0, 0.65);
}

.inv-box ::v-deep .ant-form-item-children {
  font-weight: 500;
  color: #000;
}

.remarks-box ::v-deep .ant-form-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
}

.tips-rad {
  color: #fff;
  min-width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  display: inline-block;
  font-size: 12px;
  background: red;
  margin-right: 4px;
  border-radius: 100%;
}

::v-deep .ant-form-item-control {
  line-height: 0px !important;
}


::v-deep .ant-select-disabled {
  color: #000;
}


::v-deep .ant-descriptions-title {
  margin-bottom: 0 !important;
}
</style>
