<template>
  <div class="page">
    <!-- 开票信息 -->
    <a-form-model style="width:100%;border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
      <div class="ant-descriptions-title">销售方信息</div>
      <div style="display: flex;">
        <a-form-model-item label="销售方信息" required style="width: 30%;">
          <div style="position: relative;">
            <a-select v-model="form.sellerId" @change="checkoutSeller" style="width: 100%" placeholder="请选择销售方信息"
              disabled>
              <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item label="发票类型" required style="width: 30%;margin:0 20px;">
          <div style="position: relative;">
            <a-select disabled v-model="form.invoiceTypeId" style="width: 100%"
              placeholder="请选择发票类型">
              <a-select-option :value="item.id" v-for="item in TypeList" :key="item.id"
                @click="invoiceTypeChange(item.invoiceType)">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>

      </div>
    </a-form-model>

    <!-- 发票信息 -->
    <div class="inv-box">
      <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin-bottom:16px;">
        <div class="ant-descriptions-title">买方信息</div>
        <div style="width:100%;display: flex;flex-wrap:wrap;">
          <a-form-model-item label="公司名称" required style="width: 22%;">
            <span>{{ form.sfaInvoice.invoiceHeading }}</span>
          </a-form-model-item>
          <a-form-model-item label="发票抬头" required style="width: 22%;">
            <span>{{ form.sfaInvoice.invoiceHeading }}</span>
          </a-form-model-item>
          <a-form-model-item label="统一社会信用代码/纳税人识别号" required style="width: 22%;">
            <span>{{ form.sfaInvoice.dutyParagraph }}</span>
          </a-form-model-item>
          <a-form-model-item label="开户行" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.bank }}</span>
          </a-form-model-item>
          <a-form-model-item label="发票类型" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.invoiceTypeName }}</span>
          </a-form-model-item>
          <a-form-model-item label="账号" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.bankAccount }}</span>
          </a-form-model-item>
          <a-form-model-item label="地址" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.address }}</span>
          </a-form-model-item>
          <a-form-model-item label="电话" :required="invoiceType == 2" style="width: 22%;">
            <span>{{ form.sfaInvoice.phone }}</span>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>

    <!-- 开票明细 -->
    <a-form-model style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin:16px 0;">
      <infoTable :data="sfaInvoiceApplyDetailsContentList" :sellerId="this.form.sellerId"
        @sendCententList="sendCententList"></infoTable>
      <div style="height: 15px"></div>
      <div style="font-weight: 700;color:#000;margin-bottom: 10px;">
        开票金额：<span style="color:red;">¥{{ form.actualAmount }}</span>
      </div>
      <a-form-item label="发票备注">
        <a-textarea v-model="form.remarks" :disabled="this.$route.query.isDetail"
          :placeholder="this.$route.query.isDetail ? '无备注' : '1、该备注信息会展示在发票上展示，如有需要请录入，不需要则无需填写！2、建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到！'"></a-textarea>
      </a-form-item>
    </a-form-model>

    <a-form-model v-if="form.sfaInvoiceApplyDetailsList && form.sfaInvoiceApplyDetailsList.length > 0" style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin:16px 0;">
      <div class="ant-descriptions-title">销售单明细</div>
      <a-table :columns="columns" :data-source="form.sfaInvoiceApplyDetailsList" :pagination="false" bordered size="small">
        <template slot="createTime" slot-scope="text">
          <p>{{ formatDateMin(text) }}</p>
        </template>
      </a-table>
    </a-form-model>

    <a-form-model v-if="invoiceSrc.length > 0" style="border: 1px solid rgb(245, 245, 245); padding: 10px 15px;margin:16px 0;">
      <div class="ant-descriptions-title">发票附件</div>
      <div v-for="item in invoiceSrc" :key="item">
        <embed :src="item" type="application/pdf" width="100%" height="600px" />
      </div>
    </a-form-model>

    <!-- 说明 -->
    <div class="remarks-box">
      <div class="ant-descriptions-title">开票申请说明</div>
      <div v-if="this.$route.query.isDetail">
        {{ form.applyExplain ? form.applyExplain : '无说明'}}
      </div>
      <div v-else>
        <a-textarea v-model="form.applyExplain" 
        :placeholder="this.$route.query.isDetail ? '无说明' : '仅应用于内部审批备注，发票不展示！'"></a-textarea>
      </div>
    
    </div>

    <!--驳回原因-->
    <div class="remarks-box" v-if="this.$route.query.isDetail && form.applyStatus == 5">
      <div class="ant-descriptions-title">驳回原因</div>
      {{form.rejectReason ? form.rejectReason : '-'}}
    </div>

  </div>
</template>
<script>
import { request } from "@/utils/request";
import infoTable from './components/invoiceInfoTable.vue'

export default {
  components: { infoTable },
  data() {
    return {
      columns: [{
        title: "类型",
        dataIndex: "invoicingTypeName",
        scopedSlots: { customRender: "invoicingTypeName" },
        align: "center",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        scopedSlots: { customRender: "createTime" },
        align: "center",
      },
      {
        title: "订单号",
        dataIndex: "detailCode",
        scopedSlots: { customRender: "detailCode" },
        align: "center",
      },
      {
        title: "开票金额",
        dataIndex: "amount",
        scopedSlots: { customRender: "amount" },
        align: "center",
      },
      ],//销售单
      customerData: [],//客户列表
      invoiceList: [],//发票
      dataSource: [],//销售单列表
      resDataSource: [],//宅配列表
      queryData: {
        page: 0,
        size: 20,
        // f5b8a7ae-2bf6-4265-bb73-817ad6ebc17b
      },

      sellerList: [],//主体公司
      // 列表总页数
      total: 0,
      // 列表加载中
      tabLoading: false,
      tabOutLoading: false,
      resLoading: false,
      // 货堆列表参数
      pileParams: {
        page: 0,
        size: 10,
        type: 20,
        buyCustomerId: ""
      },
      // 宅配列表参数
      resParams: {
        page: 0,
        size: 10,
        type: 21,
        buyCustomerId: ""
      },
      selectInvoice: {},//选中的发票信息
      TypeList: [],
      // 提交表单
      form: {
        amount: 0,//开票金额
        sfaInvoiceApplyDetailsList: [],
      },
      tempSfaInvoiceApplyDetailsList: [],//接收子组件传来的sfaInvoiceApplyDetailsList
      invoiceType: 0,//发票类型
      invoiceAmount: 0,//可开票金额
      glAmout: '',//关联开票金额
      totalActualAmount: 0,//合计明细开票金额
      sfaInvoiceApplyDetailsContentList: [],
      invoicingType: 1,
      EditSfaInvoiceApplyDetailsList: [],
      invoiceSrc:[]
    };
  },
  mounted() {
    this.getUser();//客户列表
    this.getSellerList();//主体公司
    // 详情
    this.getDetails();
  },
  watch: {
    'form.sellerId': function (newVal, oldValue) {
      console.log(newVal, oldValue, 'newVal');
      this.getInoviceTypeList();
    }
  },
 
  methods: {
    getDetails() {
      if (this.$route.query.id) {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/' + this.$route.query.id, 'get').then(res => {
          this.form = res.data.data;
          this.EditSfaInvoiceApplyDetailsList = this.form.sfaInvoiceApplyDetailsList
          this.queryData.customerId = this.form.customerId;
          this.queryData.invoiceId = this.form.invoiceId;
          // 开票明细
          this.sfaInvoiceApplyDetailsContentList = this.form.sfaInvoiceApplyDetailsContentList;
          // 明细开票金额
          this.sfaInvoiceApplyDetailsContentList.forEach(item => {
            this.totalActualAmount = this.totalActualAmount + item.money
          })
          // 获取该客户发票列表
          this.getInoviceList();
          if(this.form.invoiceUrl) {
            let invoiceArr = this.form.invoiceUrl.split(',').map(item => item.trim());
            invoiceArr.forEach(item=>{
              request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/download', 'post', { soundRecordingUrl: item }).then(res => {
              if (res.data) {
                  const link = document.createElement('a');
                  link.href = res.data;
                  // 根据文件类型设置合适的文件名和扩展名
                  if (res.data.includes('.pdf')) {
                      link.download = 'document.pdf';
                  } else if (res.data.includes('.jpg')) {
                      link.download = 'image.jpg';
                  } else {
                      // 默认情况下设置一个通用的文件名和扩展名
                      link.download = 'file';
                  }
                  this.invoiceSrc.push(res.data)
                  // link.style.display = 'none';
                  // document.body.appendChild(link);
                  // link.click();
                  // document.body.removeChild(link);
              } else {
                  this.$message.error(res.data);
              }
            });
            })
          }
         
          // 关联
          if (this.$route.query.status) {
            this.invoicingType = this.$route.query.type;
            this.glAmout = this.form.actualAmount
            this.getList();
            setTimeout(() => {
              this.selectInvoiceChange(this.form.invoiceId);
            }, 1500)
          }
        });
      }
    },
    invoiceTypeChange(type) {
      this.invoiceType = type;
    },
 

   
    selectChange() {
      delete this.queryData.invoiceId;
      //获取该客户发票列表
      this.getInoviceList();
      this.getList();// 获取销售单数据
      this.getSellerList();//获取卖方主体
      this.getPileList();//获取货堆列表
      this.getResList();//获取宅配列表
    },
    selectInvoiceChange(id) {
      this.invoiceList.forEach(item => {
        if (id == item.id) {
          this.selectInvoice = item;
        }
      })
    },
   
    // 开票详情数组
    sendCententList(data) {
      console.log(data, 'dat11111a')
      this.form.sfaInvoiceApplyDetailsContentList = data;
      this.totalActualAmount = 0;
      this.sfaInvoiceApplyDetailsContentList = data;
      data.forEach(item => {
        console.log(11111)
        this.totalActualAmount += item.money
        // this.totalActualAmount = (this.totalActualAmount + item.money).toFixed(2)
        console.log(this.totalActualAmount, 'this.totalActualAmount')
      })
    },
   
    // 卖方主体
    getSellerList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
        if(!this.$route.query.isDetail) {
          this.form.sellerId = this.sellerList[0].id
        }
        this.getInoviceTypeList()
      })
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getList()
    },

    // 获取该公司发票种类
    getInoviceTypeList() {
      if (this.form.sellerId) {
        request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/type/list/" + this.form.sellerId, "get").then((res) => {
          if (res.data.code == 200) {
            this.TypeList = res.data.data;
          }
        })
      }
    },
  
    // 获取用户发票列表
    getInoviceList() {
      request(process.env.VUE_APP_API_BASE_URL + "sfa/invoice/list/" + this.queryData.customerId, "get").then((res) => {
        this.invoiceList = res.data.data;
      });
    },
    getUser() {
      // 客户列表
      request(process.env.VUE_APP_API_BASE_URL + "sfa/customer/list/all", "get").then((res) => {
        this.customerData = res.data.data;
      });
    },
      // 格式化年月日
      formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
            return result;
        },
 
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 140px);
  /* display: flex; */
  /* overflow: hidden; */
  padding: 20px;
  box-sizing: border-box;
}

.page ul {
  padding: 0;
}

.page-table {
  min-height: 40vh;
}

.page li {
  height: 100%;
}




.page-box {
  margin-left: auto;
  padding-right: 46px;
  margin-top: 10px;
}

.sale-list {
  display: flex;
  font-size: 15px;
  font-weight: 500;
}

.sale-list p {
  margin-right: 20px;
}

.page-info {
  position: absolute;
  right: 10px;
  top: 120px;
  z-index: 99999;
  padding-right: 20px;
}

::v-deep .ant-select-disabled .ant-select-selection {
  background: none;
}

::v-deep .ant-select-selection {
  border: none;
}

::v-deep .ant-select-arrow {
  display: none;
}

::v-deep .ant-select-selection-selected-value {
  color: #000;
}

::v-deep .ant-form-item-control {
  line-height: 0px !important;
}

::v-deep .ant-input[disabled] {
  border: 0;
  background: none;
  color: #000;
}
::v-deep .ant-form-item {
  color:#000;
}

.inv-box ::v-deep .ant-form-item-label>label {
  color: rgba(0, 0, 0, 0.65);
}

.inv-box ::v-deep .ant-form-item-children {
  font-weight: 500;
  color: #000;
}

.remarks-box ::v-deep .ant-form-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
}


::v-deep .ant-descriptions-title {
  margin-bottom: 0 !important;
}
</style>
