<template>
    <a-card style="background-color: #fff;">
        <a-tabs default-active-key="20" @change="callback">

            <a-tab-pane key="20" tab="已生成">
            </a-tab-pane>
            <a-tab-pane key="10" tab="草稿">
            </a-tab-pane>
            <a-tab-pane key="120" tab="已取消">
            </a-tab-pane>
            <a-tab-pane key="" tab="全部">
            </a-tab-pane>
        </a-tabs>
        <div :class="advanced ? 'search' : null">
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="需求类型" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-select allowClear v-model="queryData.needOrderDetailType" style="width: 100%"
                                    size="middle" placeholder="请选择需求类型" option-label-prop="label">
                                    <a-select-option value="100" label="慰问品">慰问品</a-select-option>
                                    <!-- <a-select-option value="102" label="线上活动">线上活动</a-select-option> -->
                                    <!-- <a-select-option value="103" label="线下活动">线下活动</a-select-option> -->
                                </a-select>
                            </a-form-item>

                        </a-col>
                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="产品类型" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-select allowClear v-model="queryData.needOrderDetailSubType" placeholder="请选择产品类型"
                                    size="middle" option-label-prop="label">
                                    <a-select-option value="101" label="货堆">货堆</a-select-option>
                                    <a-select-option value="102" label="宅配">宅配</a-select-option>
                                    <a-select-option value="103" label="定制提货券">定制提货券</a-select-option>
                                    <!-- <a-select-option value="6" label="其他">其他</a-select-option> -->
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="客户名称" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-select show-search :value="searchVal" placeholder="请输入客户名称" style="width: 100%;"
                                   :default-active-first-option="false"
                                    :show-arrow="false" :filter-option="false" :not-found-content="null"
                                    @search="fetchUser" @change="handleSearchChange">
                                    <a-select-option v-for="d in customerData" :key="d.name" :label="d.name"
                                        :value="d.id">
                                        {{ d.name }}
                                    </a-select-option>
                                </a-select>
                                <!-- <a-select show-search  v-model="queryData.customerId" style="width: 100%" size="middle"
                                    placeholder="请选择客户" :default-value="null" option-label-prop="label">
                                    <a-select-option :value="item.id" :label="item.name"
                                        v-for=" (item, index) in customerData" :key="index">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select> -->
                            </a-form-item>
                        </a-col>

                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="创建人" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-select v-model="queryData.createBy" style="width: 100%" size="middle"
                                    placeholder="请选择创建人" :default-value="null" :filter-option="filterOption"
                                    option-label-prop="label">
                                    <a-select-option :value="item.id" :label="item.name"
                                        v-for=" (item, index) in userData" :key="index">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="客户经理" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-select v-model="queryData.staffIds" style="width: 100%" size="middle" mode="multiple"
                                    placeholder="请选择客户经理" :default-value="null" :filter-option="filterOption"
                                    option-label-prop="label">
                                    <a-select-option :value="item.id" :label="item.name"
                                        v-for=" (item, index) in userData" :key="index">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="产品状态" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-select allowClear v-model="queryData.needOrderDetailStatus" style="width: 100%"
                                    placeholder="请选择成交状态" option-label-prop="label">
                                    <a-select-option value="10" label="草稿">草稿</a-select-option>
                                    <a-select-option value="20" label="审批中">审批中</a-select-option>
                                    <a-select-option value="30" label="方案设计">方案设计</a-select-option>
                                    <a-select-option value="40" label="审批驳回">审批驳回</a-select-option>
                                    <a-select-option value="50" label="等待中标">等待中标</a-select-option>
                                    <a-select-option value="60" label="已中标">已中标</a-select-option>
                                    <a-select-option value="70" label="未中标">未中标</a-select-option>
                                    <a-select-option value="80" label="等待商品信息核验">等待商品信息核验</a-select-option>
                                    <a-select-option value="90" label="待生成销售单">待生成销售单</a-select-option>
                                    <a-select-option value="100" label="商品信息核验未通过">商品信息核验未通过</a-select-option>
                                    <a-select-option value="110" label="销售单已生成">销售单已生成</a-select-option>
                                    <a-select-option value="120" label="已取消">已取消</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="创建时间" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-row>
                                    <a-range-picker size="middle"
                                        :ranges="{ '今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] }"
                                        @change="dateChange" :value="dateValue" />
                                </a-row>
                            </a-form-item>
                        </a-col>
                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="需求名称" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-input size="middle" v-model="queryData.name" placeholder="请输入需求名称" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="iptmd" :sm="iptsm">
                            <a-form-item label="需求单号" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                                <a-input v-model="queryData.code" placeholder="请输入需求单号" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="iptmd" :sm="iptsm">
                            <span class="btn-box" style="margin-top: 6px; display: inline-block;">
                                <!-- <a-col :md="12" :sm="20"> -->
                                <a-button @click="resetQuery">重置</a-button>
                                <!-- </a-col> -->
                                <!-- <a-col :md="12" :sm="20"> -->
                                <a-button style="margin-left: 8px" type="primary" @click="searchChange">查询</a-button>
                                <!-- </a-col> -->
                                <a-button style="margin-left: 8px" @click="openModal" type="primary">创建需求单</a-button>

                            </span>
                        </a-col>
                    </a-row>
                </div>
            </a-form>
            <a-row>
                <!-- <a-button @click="openModal" type="primary">创建需求单</a-button> -->
            </a-row>
        </div>


        <div style="margin-top: 20px;">
            <div style="margin-bottom: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
            queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
            <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" :pagination="false" bordered
                size="small" :scroll="{ x: 1500 }">
                <template slot="createTime" slot-scope="text,record">{{ record.createTime ?
            formatDateMin(record.createTime) : '' }}</template>
                <template slot="modifyTime" slot-scope="text,record">
                    <span v-if="text">{{ formatDateMin(record.modifyTime) }}</span>
                    <span v-else>-</span>
                </template>
                <template slot="staffName" slot-scope="text">
                    <p v-if="text">{{ text }}</p>
                    <p v-else>-</p>
                </template>

                <template slot="customerName" slot-scope="text">
                    <a-popover>
                        <template slot="content">
                            {{ text }}
                        </template>
                        {{ text }}
                    </a-popover>
                </template>
                <template slot="needOrderDetailStatus" slot-scope="record,text">
                    <a>
                        {{ text.needOrderDetailStatusDesc }}
                        <a-tooltip>
                            <template slot="title">
                                {{ text.reason }}
                            </template>
                            <a-icon v-if="text.needOrderDetailStatus == 120 && text.reason" style="color:red;"
                                type="exclamation-circle" />
                        </a-tooltip>
                    </a>
                </template>


                <template slot="action" slot-scope="record">
                    <div class="action-box">
                        <a @click="details(record)" v-if="record.status != 10">详情</a>
                        <!-- <a @click="details(record)" v-if="record.status != 10 && record.needOrderDetailStatus != 40">详情</a> -->
                        <a @click="handleClick(record)" v-if="record.status == 10 || record.needOrderDetailStatus == 40">编辑</a>
                        <!-- <a @click="returnChange(record)" v-if="record.status == 20">撤回</a> -->
                    </div>
                </template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size"
                    @change="handlePageChange" />
            </div>
        </div>



    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import debounce from 'lodash/debounce';

const renderContent = (value) => {
    const obj = {
        children: value,
        attrs: {},
    };
    return obj;
};
export default {
    name: 'QueryList',
    data() {
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 900);
        return {
            isModalVisible: false,
            currentFormData: {},
            iptmd: 5,
            iptsm: 22,
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: [
                {
                    title: '需求名称',
                    dataIndex: 'name',
                    align: 'center',
                    // ellipsis: true,
                    customRender: (value, row, index) => {
                        const obj = {
                            children: <a onClick={() => this.handleClick(row)}>{value}</a>,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 180
                }, {
                    title: '客户名称',
                    dataIndex: 'customerDesc',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    align: 'center',
                    width: 180
                },
                {
                    title: '创建人',
                    dataIndex: 'createByDesc',
                    align: 'center',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 180
                },
                {
                    title: '客户经理',
                    dataIndex: 'staffDesc',
                    align: 'center',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 180
                },
                {
                    title: '项目等级',
                    dataIndex: 'projectTypeDesc',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },

                    align: 'center',
                    width: 180
                },
                {
                    title: '合计产品预算',
                    dataIndex: 'marketPrice',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },

                    align: 'center',
                    width: 180
                },
                {
                    title: '需求单号',
                    dataIndex: 'code',
                    align: 'center',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },

                    width: 180
                },
                {
                    title: '需求类型',
                    dataIndex: 'needOrderDetailTypeDesc',
                    align: 'center',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },

                    width: 180
                },
                {
                    title: '产品类型',
                    dataIndex: 'needOrderDetailSubTypeDesc',
                    align: 'center',
                    customRender: renderContent,
                    width: 180
                },
                // {
                //     title: '方案设计',
                //     dataIndex: 'needOrderDetailPlanTypeDesc',
                //     align: 'center',
                //     customRender: renderContent,
                //     width: 180
                // },
                {
                    title: '产品状态',
                    dataIndex: 'needOrderDetailStatus',
                    scopedSlots: { customRender: 'needOrderDetailStatus' },

                    // customRender: renderContent,
                    align: 'center',
                    width: 180
                },
                {
                    title: '最后更新时间',
                    dataIndex: 'modifyTime',
                    scopedSlots: { customRender: 'modifyTime' },
                    align: 'center',
                    width: 180
                },
                {
                    title: '操作人',
                    dataIndex: 'modifyByDesc',
                    align: 'center',
                    width: 180
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    scopedSlots: { customRender: 'createTime' },
                    align: 'center',
                    width: 180
                },
                {
                    title: '操作',
                    scopedSlots: { customRender: 'action' },
                    align: 'center',
                    fixed: 'right',
                    width: 160
                }
            ],
            dataSource: [],
            tabLoading: false,
            selectedRows: [],
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
                status: 20
            },
            currentPage: 1,
            dateValue: "",
            searchVal:[]
        }
    },
    mounted() {
        this.getData()
        // this.getCustList();
        this.getUserList();//销售列表
    },
    // beforeCreate() {
    //   this.form = this.$form.createForm(this, { name: 'validate_other' });
    // },
    watch: {
        $route() {
            this.getData();
            this.initData(); //初始化数据
        },
    },
    computed: {

        userPermissions() {
            return this.$store.state.account.user.authorityList;
        }
    },
    methods: {
        fetchUser(value) {
            if (!value) {
                return false;
            }
            this.lastFetchId += 1;
            const fetchId = this.lastFetchId;
            this.customerData = [];
            this.fetching = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 50, name: value }).then(res => {
                if (fetchId !== this.lastFetchId) {
                    return;
                }
                this.customerData = res.data.data.data;
                this.fetching = false;
            }).catch(error => {
                console.error('Error fetching user:', error);
            });
        },
        handleSearchChange(searchVal) {
            this.queryData.customerId = searchVal;
            Object.assign(this, {
                searchVal,
                fetching: false,
            });
            const selectedCustomer = this.customerData.find(customer => customer.id === searchVal);
            if (!this.form.name) {
                this.form.name = this.getNowDate() + selectedCustomer.name
            }
        },
        searchChange() {
            this.queryData.page = 0;
            this.currentPage = 1;
            this.getData()
        },
        details(record) {
            this.$router.push({
                path: '/sfa_demandList/sfa_demand_details',
                query: {
                    id: record.needOrderDetailId,
                    needId: record.id,
                }
            })
        },
        returnChange(record) {
            this.$confirm({
                title: '您确定要撤回吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/updateStatus', 'post', {
                        id: record.id, status: 120
                    }).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success('撤回成功！')
                            this.getData()
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
                }
            });

        },
        handleClick(row) {
            // 在这里处理点击事件，使用 row 来访问相关数据
            this.$router.push({
                path: '/sfa_demandList/sfa_demand_add',
                query: {
                    id: row.id,
                    code: row.code
                }
            })
        },
        findDuplicateIds(array) {
            const idMap = {};
            const duplicates = [];

            array.forEach((item, index) => {
                if (idMap[item.id] === undefined) {
                    idMap[item.id] = index;
                } else {
                    if (!duplicates.some(duplicate => duplicate.id === item.id)) {
                        duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
                    } else {
                        const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
                        existingDuplicate.indexes.push(index);
                    }
                }
            });

            return duplicates;
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
            return result
        },
        callback(e) {
            this.queryData.status = e;
            this.queryData.page = 0;
            this.currentPage = 1;
            this.getData();
        },
        openModal() {
            this.$router.push('/sfa_demandList/sfa_demand_add')
            // this.currentFormData = {}; // 重置表单数据
            // this.isModalVisible = true;
        },
        handleSave(newData) {
            // 处理保存逻辑，例如调用 API 接口
            console.log('保存的数据:', newData);
            this.isModalVisible = false;
            // 更新表格数据
        },
        handleModalVisible(visible) {
            this.isModalVisible = visible;
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        // 销售列表
        getUserList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
                if (res.data.code == 200) {
                    this.userData = res.data.data
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 客户列表
        // getCustList() {
        //     request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list/all', 'get').then(res => {
        //         if (res.data.code == 200) {
        //             this.customerData = res.data.data
        //         } else {
        //             this.$message.error(res.data.message)
        //         }
        //     })
        // },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
        // 选择日期
        dateChange(date, dateString) {
            console.log(date, dateString);
            this.queryData.dateMin = dateString[0].toString();
            this.queryData.dateMax = dateString[1].toString();
            this.dateValue = date
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        resetQuery() {
            this.queryData = {
                page: 0,
                size: 10,
            }
            this.searchVal = []
            this.dateValue = ''
            this.getData()
        },

        // 获取列表 
        getData() {
            this.tabLoading = true;
            let params = JSON.parse(JSON.stringify(this.queryData));
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/list', 'post', params).then(res => {
                // this.$message.destroy();
                let data = res.data.data;
                this.tabLoading = false;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.action-box a {
    margin-right: 8px;
}

.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}

::v-deep .ant-tabs-tab {
    font-weight: bolder !important;
}
</style>