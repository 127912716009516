<template>
  <div>
    <a-card style="background-color: #fff;">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="需求类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.type" style="width: 100%" placeholder="请选择需求类型"
                    :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.value" :label="item.label" v-for=" (item, index) in needTypeList"
                      :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="产品类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.productType" style="width: 100%" placeholder="请选择需求类型"
                    :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.value" :label="item.label" v-for=" (item, index) in productTypeList"
                      :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="销售单状态" :labelCol="{ span: 6 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-select allowClear v-model="queryData.status" style="width: 100%" placeholder="请选择销售单状态"
                    :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in [{id:1,name:'进行中'},{id:2,name:'已完成'},{id:3,name:'已取消'}]"
                      :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="客户名称" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select show-search :value="searchVal" placeholder="请输入客户名称" :default-active-first-option="false"
                    :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
                    @change="handleSearchChange">
                    <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <!-- <a-form-item label="销售单客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.salespersonId" style="width: 100%"  show-search
                    placeholder="请选择销售单客户经理" :default-value="null" :filter-option="filterOption"
                    option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData"
                      :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item> -->
                <a-form-item label="当前客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.staffIdList" mode="multiple" style="width: 100%"  placeholder="请选择客户经理"
                    :default-value="null" :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> 


              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="产品售价" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-row>
                    <a-col :span="11">
                      <a-input v-model="queryData.amountMin" placeholder="最小价格" />
                    </a-col>
                    <a-col :span="2" style="text-align:center">
                      -
                    </a-col>
                    <a-col :span="11">
                      <a-input v-model="queryData.amountMax" placeholder="最大价格" />
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-range-picker :value="dateValue" :ranges="{
        '今天': [moment(), moment()],
        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '当月': [moment().startOf('month'), moment().endOf('month')],
        '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }" @change="dateChange" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="销售单号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-input v-model="queryData.code" placeholder="请输入销售单号" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;" @click="search">查询
                </a-button>

              </a-col>
            </a-row>

            <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至
              {{ queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项
            </div>
          </div>

        </a-form>

      </div>


      <div>
        <a-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false" bordered size="small"
          :scroll="{ x: 1500 }">
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="updateTime" slot-scope="text">{{ formatDate(text) }}</template>

          <template slot="needType" slot-scope="text">
            <span v-if="!text">线下活动</span>
          </template>
          <template slot="projectTypeDesc" slot-scope="text">
            <span v-if="!text">-</span>
            <span v-else>{{ text }}</span>
          </template>
          <template slot="invoiceAmount" slot-scope="text">
              <span>{{ text ? text.toFixed(2):'0.00' }}</span>
          </template>
          <template slot="amount" slot-scope="text">
              <span>{{ text ? text.toFixed(2):'0.00' }}</span>
          </template>
          <template slot="plfPrice" slot-scope="text">
              <span>{{ text ? text.toFixed(2):'0.00' }}</span>
          </template>
          
          <template slot="action" slot-scope="text,record">
            <div class="action-box">
              <a @click="details(record)">详情</a>
            </div>
          </template>
        </a-table>
        <div class="page-box">
          <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
            :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
            show-size-changer />
        </div>


      </div>

    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { publicMixin } from '@/mixin/public'
import debounce from 'lodash/debounce';

const columns = [
  { title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80, },
 
  {
    title: '销售单号',
    dataIndex: 'code',
    align: 'center'
  },
  {
    title: '客户名称',
    dataIndex: 'customerDesc',
    align: 'center'
  },
  {
    title: '销售单客户经理',
    dataIndex: 'salespersonName',
    align: 'center'
  },
  {
    title: '当前客户经理',
    dataIndex: 'staffDesc',
    align: 'center'
  },
  {
    title: '销售单状态',
    dataIndex: 'statusDesc',
    align: 'center'
  },
  {
    title: '优加底价',
    dataIndex: 'plfPrice',
    scopedSlots: { customRender: 'plfPrice' },
    align: 'center'
  },
  {
    title: '产品售价',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' },
    align: 'center'
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmount',
    scopedSlots: { customRender: 'invoiceAmount' },
    align: 'center'
  },
  {
    title: '需求类型',
    dataIndex: 'needType',
    scopedSlots: { customRender: 'needType' },
    align: 'center'
  },
  {
    title: '产品类型',
    dataIndex: 'typeDesc',
    align: 'center'
  },
  {
    title: '最后更新时间',
    dataIndex: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    align: 'center'
  },
  {
    title: '操作人',
    dataIndex: 'updateByName',
    align: 'center'
  },
  {
    title: '项目等级',
    dataIndex: 'projectTypeDesc',
    scopedSlots: { customRender: 'projectTypeDesc' },
    align: 'center'
  },
 
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center'
  },
  // {
  //   title: '操作',
  //   scopedSlots: { customRender: 'action' },
  //   align: 'center',
  //   fixed: 'right',
  //   width: 160
  // }
]

export default {
  name: 'QueryList',
  mixin: [publicMixin],
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      searchVal:[],
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],

      // 筛选
      queryData: {
        page: 0,
        size: 10,
      },

      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0,
      currentPage: 1,
      total: 0,
      brandList: [],
      pdtCatList: [],
      dateValue: "",
      totalText: null,
      needTypeList: [
        {
          label: '线下活动',
          value: 5
        }
      ],
      // 活动类型
      productTypeList: [
        {
          label: '运动会',
          value: 1
        },
        {
          label: '手作',
          value: 2
        },
        {
          label: '游园会',
          value: 3
        },
        {
          label: '外出团建',
          value: 4
        },
        {
          label: '年会',
          value: 5
        },
        {
          label: '培训',
          value: 6
        },
        {
          label: '其他',
          value: 0
        }
      ],
    }
  },
  mounted() {
    // 列表
    this.getData();
    // 销售
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post',{}).then(res => {
      this.userData = res.data.data
    })
    // // 客户列表
    // request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
    //   this.customerData = res.data.data
    // })
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 50, name: value }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerData = res.data.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchChange(searchVal) {
      this.queryData.customerId = []
      this.queryData.customerId = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    search() {
      this.queryData.page = 0;
      this.getData()
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.getData()
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    // 获取列表
    getData() {
      this.tableLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/offlineActivity/list', 'post', this.queryData).then(res => {
        this.tableLoading = false;
        let data = res.data.data;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    exportTask() {
      this.$message.loading('处理中')
      let params = this.queryData;
      params.remarks = ''
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/exportBrand', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功！')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      return result
    },
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
      }
      this.dateValue = ''
      this.searchVal = []
      this.dateValueOrderTime = ""
      this.getData()
    },
    details(row) {
      this.$router.push({
        path:'sfa_activity_details',
        query:{
          id:row.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

.spin-content {
  color: #5542F6;
  margin-top: 10px;
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}
</style>