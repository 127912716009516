
export let activityColumns = [
    {title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80,},
    {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: {customRender: 'createTime'},
        width: 160,
        align: 'center'
    },
    {
        title: '分销商名称',
        dataIndex: 'distributorName',
        width:130,
        align: 'center'
    },

    {
        title: '活动主题',
        dataIndex: 'theme',
        width:130,
        align: 'center'
    },
    {
        title: '活动类型',
        dataIndex: 'typeName',
        width:130,
        align: 'center'
    },
    {
        title: '买方名称',
        dataIndex: 'customerName',
        width:240,
        align: 'center'
    },
    {
        title: '活动申请人',
        dataIndex: 'salespersonName',
        width:130,
        align: 'center'
    },
    {
        title: '用户端上架时间',
        dataIndex: 'showTime',
        scopedSlots: {customRender: 'showTime'},
        width:150,
        align: 'center'
    },
    {
        title: '用户端下架时间',
        dataIndex: 'closeTime',
        scopedSlots: {customRender: 'closeTime'},
        width:150,
        align: 'center'
    },
    {
        title: '活动开始时间',
        dataIndex: 'startTime',
        width:150,
        scopedSlots: {customRender: 'startTime'},
        align: 'center'
    },
    {
        title: '活动结束时间',
        dataIndex: 'endTime',
        width:150,
        scopedSlots: {customRender: 'endTime'},
        align: 'center'
    },
    {
        title: '活动状态',
        dataIndex: 'acStatusName',
        width:150,
        align: 'center'
    },
    {
        title: '审批状态',
        dataIndex: 'statusName',
        width:150,
        align: 'center'
    },
    {
        title: '配置状态',
        dataIndex: 'allocationStatusName',
        width:130,
        align: 'center'
    },
    {
        title: '参与人数',
        dataIndex: 'number',
        width:130,
        align: 'center'
    },
    {
        title: '奖品预算金额',
        dataIndex: 'prizeBudgetAmount',
        width:130,
        align: 'center'
    },
    {
        title: '操作',
        fixed:'right',
        align: 'center',
        width:150,
        scopedSlots: {customRender: 'action'},
    },
]

// 排行榜
export let chartColumns = [
    {title: '排名',  scopedSlots: {customRender: 'orderNum'},dataIndex:'orderNum', align: 'center', width: 40,},
    {
        title: '公司',
        dataIndex: 'customerName',
        width: 140,
        align: 'center'
    },
    {
        title: '部门',
        dataIndex: 'customerDeptName',
        width: 100,
        align: 'center'
    },
    {
        title: '姓名',
        dataIndex: 'name',
        width: 90,
        align: 'center'
    },
    {
        title: '手机号',
        dataIndex: 'mobile',
        width: 90,
        align: 'center'
    },
    {
        title: '累计步数',
        dataIndex: 'totalStepNumber',
        width: 100,
        align: 'center'
    },
    {
        title: '累计*数量',
        dataIndex: 'totalScore',
        width: 80,
        align: 'center'
    },
    {title: '完成时间',dataIndex: 'modifyTime',width: 80,align: 'center', scopedSlots: {customRender: 'modifyTime'},},

]

// 每日明细
export let dailyColumns = [
    {title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 30},
    {
        title: '日期',
        dataIndex: 'day',
        width: 50,
        scopedSlots: {customRender: 'day'},
        align: 'center'
    },
    // {title: '日期',dataIndex: 'a',width: 200,align: 'center', scopedSlots: {customRender: 'createTime'},},
    {title: '姓名',dataIndex: 'name',width: 50,align: 'center'},
    {title: 'ID',dataIndex: 'userId',width: 50,align: 'center'},
    {title: '手机号',dataIndex: 'mobile',width: 70,align: 'center'},
    {title: '当日步数',dataIndex: 'totalStepNumber',width: 60,align: 'center'},
    {title: '是否获*',dataIndex: 'totalScoreText',width: 40,align: 'center'},
    {title: '完成时间',dataIndex: 'modifyTime',width: 80,align: 'center', scopedSlots: {customRender: 'modifyTime'},},
]

export let outColumns = [
    {title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80,},
    {
      title: '出库批次号',
      dataIndex: 'code',
      width: 220,
      align: 'center'
    },
    {
      title: '礼品卡名称',
      dataIndex: 'cardName',
      width: 120,
      align: 'center'
    },
    {
      title: '可开票金额',
      dataIndex:'surplusAmount',
      scopedSlots: {customRender: 'surplusAmount'},
      width: 120,
      align: 'center'
    },
    {
      title: "开票金额",
      dataIndex: "money",
      align: "center",
      scopedSlots: { customRender: "money" },
      width: 150,
  },
  // {
  //   title: '已开票金额',
  //   dataIndex: 'alreadyAmount',
  //   width:150,
  //   align: 'center'
  // },
    {
      title: '出库数量',
      dataIndex: 'outSize',
      width: 100,
      align: 'center'
    },
    {
      title: '增值金额/张',
      dataIndex: 'quota',
      scopedSlots: {customRender: 'quota'},
      width: 120,
      align: 'center'
    },
    {
      title: '线上充值/张',
      dataIndex: 'amountOnLine',
      width: 120,
      align: 'center'
    },
    {
      title: '线下充值/张',
      dataIndex: 'amountOffLine',
      width: 120,
      align: 'center'
    },
    {
      title: '实收金额/张',
      dataIndex: 'amount',
      width: 120,
      align: 'center'
    },
    {
      title: '开票状态',
      dataIndex: 'invoiceStatusName',
      width: 100,
      align: 'center'
    },
    {
      title: 'M+',
      dataIndex: 'outCardOxp',
      scopedSlots: {customRender: 'outCardOxp'},
      width: 120,
      align: 'center'
    },
    {
      title: '卖方',
      dataIndex: 'sellerName',
      width: 200,
      align: 'center'
    },
    {
      title: '出库时间',
      dataIndex: 'createTime',
      scopedSlots: {customRender: 'createTime'},
      width: 150,
      align: 'center'
    },
]

export let pileColumns = [
        {title: '序号', customRender: (text, record, index) => index + 1, align: 'center',width:80},
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          width:180,
          align: 'center'
        },
        {
          title: '销售订单号',
          dataIndex: 'code',
          width:230,
          align: 'center'
        },
        {
          title: '可开票金额',
          dataIndex: 'surplusAmount',
          width:150,
          align: 'center'
        },
        {
          title: "开票金额",
          dataIndex: "money",
          align: "center",
          scopedSlots: { customRender: "money" },
          width: 150,
      },
        // {
        //   title: '已开票金额',
        //   dataIndex: 'alreadyAmount',
        //   width:150,
        //   align: 'center',
        // },
        {
            title: '实收金额',
            dataIndex: 'amount',
            width:150,
            align: 'center'
          },
          {
            title: '开票状态',
            dataIndex: 'invoicingStatusName',
            // scopedSlots: {customRender: 'invoice'},
            width:230,
            align: 'center'
          },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          width:230,
          align: 'center'
        },
      
]

export let resColumns = [
    {title: '序号', customRender: (text, record, index) => index + 1, align: 'center',width:80},
    {
      title: '创建时间',
      dataIndex: 'createTime',
      scopedSlots: {customRender: 'createTime'},
      width:180,
      align: 'center'
    },
    // {
    //   title: '下单时间',
    //   dataIndex: 'orderTime',
    //   scopedSlots: {customRender: 'orderTime'},
    //   width: 200,
    //   align: 'center'
    // },
    // {
    //   title: '订单类型',
    //   dataIndex: 'type',
    //   scopedSlots: {customRender: 'type'},
    //   width: 100,
    //   align: 'center'
    // },
    // {
    //   title: '分销商名称',
    //   dataIndex: 'distributorName',
    //   width: 120,
    //   align: 'center'
    // },
    {
      title: '销售订单号',
      dataIndex: 'code',
      width:230,
      align: 'center'
    },
    {
      title: '可开票金额',
      dataIndex: 'surplusAmount',
      width:150,
      align: 'center'
    },
    {
      title: "开票金额",
      dataIndex: "money",
      align: "center",
      scopedSlots: { customRender: "money" },
      width: 150,
  },
    // {
    //   title: '已开票金额',
    //   dataIndex: 'alreadyAmount',
    //   width:150,
    //   align: 'center'
    // },
    {
        title: '实收金额',
        dataIndex: 'amount',
        width:150,
        align: 'center'
      },
      {
        title: '开票状态',
        dataIndex: 'invoicingStatusName',
        // scopedSlots: {customRender: 'invoice'},
        width:230,
        align: 'center'
      },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width:230,
      align: 'center'
    },
  ]