import Vue from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import './theme/index.less'
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
import '@/mock'
import store from './store'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import {initI18n} from '@/utils/i18n'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import Print from 'vue-print-nb'
const router = initRouter(store.state.setting.asyncRoutes)
const i18n = initI18n('CN', 'US')

Vue.use(Print)
Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Viser)
Vue.use(Plugins)
Vue.directive('rule-number', {
  bind(el) {
    el.addEventListener('input', (event) => {
      let value = event.target.value;

      // 去除所有非数字和小数点的字符
      value = value.replace(/[^0-9.]/g, '');
      
      // 只允许一个小数点
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts[1];
      }
      
      // 限制小数点后最多两位
      if (parts[1] && parts[1].length > 2) {
        value = parts[0] + '.' + parts[1].slice(0, 2);
      }

      // 更新输入框的值
      event.target.value = value;

      // 触发 input 事件，确保 v-model 同步更新
      event.target.dispatchEvent(new Event('input'));
    });
  }
});
bootstrap({router, store, i18n, message: Vue.prototype.$message})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
